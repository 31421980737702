.gr-radio {
    margin-bottom: 1.25rem;
    min-height: 1rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    
    input[type=radio] {
        opacity: 0;
        position: absolute;
    
        &:focus {
            ~ span {
                outline: 0;
            }
        }

        + label {
            display: inline-block;
            vertical-align: middle;
            line-height: 18px;
            padding-left: 36px;
            font-size: 1rem;
            cursor: pointer;
        }

        ~ span {
            -webkit-box-sizing: content-box;
            border: 2px solid #c7c7c7;
            background: white;
            border-radius: 50%;
            box-sizing: border-box;
            color: #6a7070;
            display: block;
            height: 1.5rem;
            left: 0;
            padding: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: 1.5rem;
        
            svg {
                opacity: 0;
            }
        }

        &:checked {
            ~ span { 
                border-color: transparent;

                svg.select-icon {
                    opacity: 1;
                    top: -8px;
                    position: relative;
                    left: 0px;
                    fill: #047A9C;
                }
            }
           
        }

        &:disabled {
            ~ span {
                border-color: #ddd;
                background-color: #f7f7f7 ;
            }
        } 
    }
    input[type=checkbox] {
        opacity: 0;
        position: absolute;
        margin: 0 0 0 3px;

        + label {
            display: inline-flex;
            align-items: center;
            line-height: 18px;
            padding-left: 40px;
            font-size: 1rem;
            cursor: pointer;
        }
        ~ span {
            -webkit-box-sizing: content-box;
            border: 2px solid #7E7F80;
            background: white;
            border-radius: 2px;
            box-sizing: content-box;
            color: #6a7070;
            display: block;
            height: 20px;
            width: 20px;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: 0;
            z-index:-1;
            svg {
                height: 24px;
                opacity: 0;
                width: 24px;
                position: absolute;
                left:-2px;
                top:-2px;
               }
        }
        &:focus ~ span {
            outline: 0;
            -webkit-box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
            box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
           }
        &:checked {
            ~ span { 
                // border-colors: transparent;

                svg.select-icon {
                    opacity: 1;
                    // top: -8px;
                    // position: relative;
                    // left: 0px;
                    fill: #047A9C;

                    @include devices(small) {
                        top: -3px;
                    }
                }
            }
           
        }
        &:disabled {
            ~ span {
                border-color: #ddd;
                background-color: #f7f7f7 ;
            }
        }
    }
    &.selected {
        input[type=radio], input[type=checkbox] {
            &:checked {
                ~ span {
                    svg.correct-icon {
                        opacity: 1;
                        top: -8px;
                        position: relative;
                        left: -1px;
                        fill: #038238;
                    }
                    
                    svg.select-icon {
                        display: none;
                    }
                }
            }
        }
    }
}