@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

$color-theme-white: #ffffffff;
$color-theme-black: #000000ff;
$color-theme-indigo: #1d3557ff;
$color-theme-text-lighttheme-default: #020917ff;
$color-theme-text-lighttheme-muted: #585858ff;
$color-theme-text-darktheme-default: #fefefeff;
$color-theme-text-darktheme-muted: #b5b5b5ff;
$color-theme-sys-light-primary: #007e53ff;
$color-theme-sys-light-on-primary: #ffffffff;
$color-theme-sys-light-primary-container: #5bdda0ff;
$color-theme-sys-light-on-primary-container: #005234ff;
$color-theme-sys-light-secondary: #5066c5ff;
$color-theme-sys-light-on-secondary: #ffffffff;
$color-theme-sys-light-secondary-container: #b9c3ffff;
$color-theme-sys-light-on-secondary-container: #293e98ff;
$color-theme-sys-light-tertiary: #00809bff;
$color-theme-sys-light-on-tertiary: #ffffffff;
$color-theme-sys-light-tertiary-container: #5ad5f9ff;
$color-theme-sys-light-on-tertiary-container: #004e5fff;
$color-theme-sys-light-error: #af2e30ff;
$color-theme-sys-light-on-error: #ffffffff;
$color-theme-sys-light-error-container: #ffb3aeff;
$color-theme-sys-light-on-error-container: #8d131bff;
$color-theme-sys-light-success: #008859ff;
$color-theme-sys-light-on-success: #ffffffff;
$color-theme-sys-light-success-container: #5bdda0ff;
$color-theme-sys-light-on-success-container: #005234ff;
$color-theme-sys-light-warning: #857100ff;
$color-theme-sys-light-on-warning: #ffffffff;
$color-theme-sys-light-warning-container: #fee264ff;
$color-theme-sys-light-on-warning-container: #221b00ff;
$color-theme-sys-light-info: #00829dff;
$color-theme-sys-light-on-info: #ffffffff;
$color-theme-sys-light-info-container: #5ad5f9ff;
$color-theme-sys-light-on-info-container: #004e5fff;
$color-theme-sys-light-outline: #757874ff;
$color-theme-sys-light-background: #ffffffff;
$color-theme-sys-light-on-background: #191c1aff;
$color-theme-sys-light-surface: #ffffffff;
$color-theme-sys-light-on-surface: #191c1aff;
$color-theme-sys-light-surface-variant: #c5c7c3ff;
$color-theme-sys-light-on-surface-variant: #444845ff;
$color-theme-surfaces-light-surface-hover: #fefefecc;
$color-theme-surfaces-light-surface-active: #fefefef5;
$color-theme-surfaces-dark-surface-hover: #000000db;
$color-theme-surfaces-dark-surface-active: #000000f5;
$color-theme-surfaces-gradient-bg-dark: #1d3557ff;
$gradient-theme-surfaces-light-gradient-main: linear-gradient(114.06deg, #fefefee6 30%, #fefefe4d 90%);
$gradient-theme-surfaces-dark-gradient-main: linear-gradient(114.06deg, #020917e6 0%, #0209174d 100%);
$gradient-theme-surfaces-gradient-primary-light: linear-gradient(101.47deg, #00a56dff 0%, #1d3557ff 100%);
$gradient-theme-surfaces-gradient-primary-dark: linear-gradient(101.47deg, #00a56dff 0%, #00677dff 100%);
$gradient-theme-surfaces-gradient-secondary-light-0: linear-gradient(159.55deg, #34b9dcff 0%, #5c71cdff 100%);
$gradient-theme-surfaces-gradient-secondary-light-1: linear-gradient(114.06deg, #00000059 0%, #00000026 100%);
$gradient-theme-surfaces-gradient-secondary-dark-0: linear-gradient(159.55deg, #34b9dcff 0%, #5c71cdff 100%);
$gradient-theme-surfaces-gradient-secondary-dark-1: linear-gradient(114.06deg, #00000059 0%, #00000026 100%);
$gradient-theme-surfaces-gradient-tertiary-light: linear-gradient(175.31deg, #5ad5f9ff 0%, #004e5fff 100%);
$gradient-theme-surfaces-gradient-tertiary-dark: linear-gradient(135.00deg, #5ad5f9ff 11%, #00677dff 100%);
$gradient-theme-surfaces-gradient-bg-light-0: linear-gradient(175.31deg, #efefefff 0%, #4ca8c3ff 100%);
$gradient-theme-surfaces-gradient-bg-light-1: #ffffff99;

.body-wrap {
    display: flex;
    min-height: 100vh; 
    background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(137.13deg, #EFEFEF 0%, #4CA8C3 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    background-size: cover, cover;
    background-attachment: fixed;
    overflow-x: hidden;
    font-family:Inter, Helvetica, sans-serif;
    .main-nav {
        flex: 0 0 6.75rem;
    }

    main {
        flex: 1;
        mix-blend-mode: normal;
        min-width: calc(100vw - 6.75rem);
        .app-header {
            background: transparent;
        }
        .wrapper {
            max-width: 64rem;
            margin: 2rem auto;

            h2 {
                margin: 1rem 0;
                font-weight:bold;
                font-size:1.5rem;
            }
        }
    }
    .section-header {
        background: $gradient-theme-surfaces-gradient-secondary-light-1, $gradient-theme-surfaces-gradient-secondary-light-0;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px 8px 0 0;
        backdrop-filter: blur(20px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem 1rem;
    
        .search-assignments {
            display: flex;
            align-items: center;
            color: $color-theme-sys-light-on-primary;
            gap: 0.5rem;
    
            select {
                background-color: $color-theme-sys-light-on-primary;
                border-radius: 22px;
                padding: 0.5rem 2rem 0.5rem 1rem;
                min-width: 15rem;
                margin-right: 1rem;
                color: $color-theme-text-lighttheme-default;
                appearance: none;
                background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                background-repeat: no-repeat;
                background-position: calc(100% - 8px) center;
    
            }
        }
    
        .actions {
            display: flex;
            align-items: center;
            gap: 1rem;
    
            .MuiButton-root {
                border: 2px solid $color-theme-sys-light-on-primary;
                color: $color-theme-sys-light-on-primary;
            }
    
            .MuiToggleButton-root {
                border: 0;
                border-radius: 50% !important;
    
                &.Mui-selected {
                    background-color: rgba(254, 254, 254, 0.56);
                    color: $color-theme-sys-light-on-secondary-container;
                }
            }
        }
    }
    
    .main-content  {
        margin: 2rem 8rem;
    }
}
.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;

    .header-logo {
        margin-bottom: 3rem;
    }

    .main-menu {
        list-style: none;
        margin: 0px;
        padding: 8px 0px;
        position: relative;
        .menu-item {
            padding: 0;
            border-radius: 50%;
            max-height: 3rem;
            margin: 1.5rem 0;
            a {
                display: block;
                border-radius: 50%;
                width: 3rem;
                height: 3rem;
                text-indent: -9999px;
                background-color: rgba(2, 9, 23, 0.32);
            }
            &.selected {
                a {
                    background-color: $color-theme-sys-light-primary-container;
                }
            }
        }
    }
}
.assignment-metadata {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.assignment-row {
    cursor: pointer;

    &:hover {
        background: $color-theme-surfaces-light-surface-hover;
    }
    .metadata {
        margin: 0;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.43;
        letter-spacing: 0.01071em;
        color: rgba(0, 0, 0, 0.6);
        display: block;
    }
    .assignment-title { 
        margin: 0px 0px 8px;
        color: rgb(2, 9, 23);
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 1.43;
        text-transform: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

span.difficulty-label {
    color: $color-theme-text-lighttheme-muted;
    font-family: Inter;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1; /* 100% */
    letter-spacing: 0.15px;
    display: block;
}
.table-wrapper {
    color: rgb(2, 9, 23);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    overflow: hidden;
    background: linear-gradient(90deg, rgba(254, 254, 254, 0.9) 30%, rgba(254, 254, 254, 0.3) 90%);
}
.ov-button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    font-family: Inter, Helvetica, sans-serif;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    border-radius: 2rem;
    border: 2px solid rgb(2, 9, 23);
    color: rgb(2, 9, 23);
    padding: 10px 24px;
    font-size: 14px;
    text-decoration: none;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.4px;
    text-transform: none;
    line-height: 1.42857;

    &.outlined {
        border: 2px solid #fff;
        color: #fff;
    }
}
.toggle-button-group {
    .toggle-button {
        display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Inter, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.75;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 11px;
    border: 0;
    border-radius: 50% !important;
    color: rgba(254, 254, 254, 0.96);
    &.selected {
        background-color: #fefefe8f;
        color: #293e98;
    }
    }
}