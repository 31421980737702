/* Old Header styles -----
.global-header {
    height: 4rem;
    position: relative;
    padding: 0.975rem 0;

    > .wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .logo {
        width: 126px;
        height: 32px;
    }

    .meta{
        font-size: 0.875rem;
        line-height: 1.5;
        color: #505759;
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;

        @include devices(large) {
            margin-right: 0;
        }

        .gr-btn {
            margin-left: 0.5rem;

            @include devices(medium) {
                display: none;
            }
        }

        .user-profile {
            font-weight: 700;
            display: flex;
            align-items: center;
            margin-right: 1rem;

            @include devices(medium) {
                display: none;
                margin-right: 0;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 6px 0 6px;
                border-color: #252525 transparent transparent transparent;
                margin-left: 0.5rem;
            }        
        }

        .user-profile--mobile {
            display: none;

            span {
                background-color: #00655B;
                color: #fff;
                display: flex;
                text-transform: uppercase;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
            }

            @include devices(medium) {
                display: flex;
            }
        }
    }

    &.wide {
        .wrapper {
            max-width: 100%;
            padding: 0 1rem 0 2rem;
        }
    }
} */


header {
    grid-row: 1;
    grid-column: 1 / span 12;
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    justify-content: space-between;
    // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.10);
    position: relative;
    max-height: 70px;
    background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.70));
   
    // background: var(--Theme-white, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(20px);
    z-index: 12;

    &.overview {
        position:fixed;
        left:0;
        right:0;
        top:0;
        // box-shadow: none;
        + main {
            padding-top:70px;

        }
    }

    .left, .right { 
        display: flex;
        align-items: center;
        gap: 0.625rem;
    }

    .right {
        label {
            font-size: 0.75rem;
            line-height: 1.5;
        }
    }

    .left {
        gap: 1.5rem;
    }

    h1 {
        font-size: 20px;
        color: #020917;
        font-weight: 600;
        font-family: 'Open Sans', Helvetica, sans-serif;
        display: flex; 
        align-items: center; 
        gap: 0.625rem;
        margin:0;
    }

    .MuiSvgIcon-root {
        fill: #585858;
    }

    .badge {
        width: 16px;
        height: 16px;
        border-radius: 85px;
        background-color: #C117BA;
        position: absolute;
        top: 5px;
        right: 5px;
        color: white;
        font-size: 10px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-weight: 600;
    }
    &.show {
        animation:headerShow 0.3s ease-in-out;
        animation-fill-mode: forwards;
    }
    &.hide {
        animation: headerHide 0.3s ease-in-out;
        animation-fill-mode:forwards;
    }
}

@keyframes headerHide {
    0% {
        transform:translateY(0)
    }
    100% {
        transform:translateY(-100px)
    }
}
@keyframes headerShow {
    0% {
        transform:translateY(-100px)
    }
    100% {
        transform:translateY(0)
    }
}