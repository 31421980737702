.player-header {
    // background: #007899;
    // background:transparent;
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0.35) 0%,rgba(255, 255, 255, 0.2) 75%, rgba(255, 255, 255, 0) 150%);
    background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.70));
    box-shadow: 0px 2px 10px 0px #0000001a;
    backdrop-filter: blur(20px);
    height: 5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 2rem;
    align-items: center;
    color: #000;
    // position: relative;
    z-index: 30;
    font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
    // box-shadow: 0px 4px 15px 0px #0000000D;

    position:fixed;
    top:0;
    width:100%;
    z-index: 112;


    @include devices(large) {
        padding: 1rem 0.5rem 1rem 1rem;
    }

    @include devices(medium) {
        height: 3rem;
    }

    .assignment-type {
        text-transform: capitalize;

        .assignment-type__wide {
            @include devices(small) {
                display: none;
            }
        }

        .assignment-type__mobile {
            display: none;

            @include devices(small) {
                display: inline-block;
            }
        }
    }
    .assignment-title {
        max-width:35ch; // do this better
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight:600;
    }
    .progress-indicator {
       flex-direction: row;
       align-items: center;
       gap:8px;
       .progress-info {
        white-space: nowrap;
       }
        .progress {
            height:9px;
            background:rgba(231, 231, 231, 1);
            // background: #010D2D40;
            // background:white;
            padding:0;
            span {
                left:0;
                top:0;
                height:100%;
                transition:width 0.3s ease-in;
                background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #7075F7 100%);
                // background:#02835C;
            }

        }
    }

    .gr-btn {
        // margin-left: 3.5rem;

        &.icon-btn-18, &.icon-btn-24 {
            svg {
                fill: #000;
            }
        }      

        &.btn-close {
            margin-left: 1rem;
            padding: 8px 20px;
            background-color: #03313E;
            border: 0;
            color: #fff;
            font-size: 1rem;

            @include devices(small) {
                margin: 0;
            }

            .mobile-view {
                display: none;

                @include devices(small) {
                    display: inline;
                }                
            }
            .desktop-view {
                display: inline;

                @include devices(small) {
                    display: none;
                }                
            }

            @include devices(small) {
                padding: 0.5rem;
                background: transparent;
            }

        }
    }

    .player-header__left {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        overflow:hidden;

        #info-icon {
            @include devices(medium) {
                display: none;
            }
        }

        .hamburger {
            display: none;
            position: relative;
            z-index: 1;
            user-select: none;
            background: transparent;
            border: none;

            @include devices(medium) {
                display: block;
            }

           > span {
               display: block;
                width: 1.5rem;
                height: 3px;
                margin-bottom: 5px;
                position: relative;
                background: #151515;
                border-radius: 3px;
                z-index: 1;
                transform-origin: 4px 0px;
                transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
           
                &:first-child {
                    transform-origin: 0% 0%;
                }
                
                &:nth-last-child(2) {
                    transform-origin: 0% 100%;
                }
            }

            &.open {
                > span {
                    opacity: 1;
                    transform: rotate(45deg) translate(-2px, -1px);
                    transform-origin: 50% 50%;
                    margin: 0;

                    &:nth-last-child(3) {
                        opacity: 0;
                        transform: rotate(0deg) scale(0.2, 0.2);
                    }

                    &:nth-last-child(2) {
                      transform: rotate(-45deg) translate(0, -1px);
                      transform-origin: 50% 50%;
                    }
                }
            }
        }
    }

    .player-header__center {
        // position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        text-align: center;
        transition: left linear 0.3s;

        @include devices(large) {
           left: 42%;
        }

        @include devices(medium) {
            display: none;
        }

        .item-type {
            text-transform: capitalize;
            font-size: 1.25rem;
            font-weight: 600;
        }

        .step-info {
            font-weight: 400;
            font-size: 0.875rem;
        }
    }

    .player-header__right {
        display: flex;
        align-items: center;
        gap:8px;

        .mobile-score-btn {
            display: none;
            margin: 0;

            svg {
                fill: none !important;
                stroke: white;
            }

            @include devices(medium) {
                display: block;

                &:hover {
                    background-color: transparent;
                }

                &:active, &:focus {
                    outline: 0;
                    border: 0
                }
            }
        }

        .score-info {
            > span {
                display: inline-block;
                margin: 0 0.25rem;

                @include devices(large) {
                    margin: 0 0.125rem;
                }

                &.label {
                    font-weight: 600;
                }

                &.inline-val {
                    font-weight: 300;
                    white-space: nowrap;
                }

                &.divider {
                    @include devices(medium) {
                        display: none;
                    }
                }

                @include devices(large) {
                    font-size: 0.875rem;
                    
                }
            }

            @include devices(medium) {
                display: none;
                min-width: 225px;
                position: absolute;
                top: calc(100% + 0.125rem);
                right: 7.25rem;
                color: #333;
                background: #fff;
                border: 1px solid #ddd;
                padding: 0.5rem 0.75rem;
                gap: 0.5rem;
                border-radius: 0 0 0.25rem 0.25rem;
                box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);     

                &:before, &:after {
                    bottom: 99%;
                    right: 12px;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                }

                &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-bottom-color: #ffffff;
                    border-width: 10px;
                    margin-right: 0px;
                }

                &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-bottom-color: #c7c7c7;
                    border-width: 12px;
                    margin-right: -2px;
                }

                &.open {
                    display: grid;
                    grid-template-columns: 50% auto;
                }
            }

            @include devices(small) {
                right: 3.25rem;
            }
        }
    }
}

.player-main {
    display: flex;
    // height: calc(100vh - 0px);
    position: relative;
    background: #FFF;
//    margin-top:70px;

    @include devices(medium) {
        // height: calc(100vh - 3rem);
    }

    aside {
        max-width: 375px;
        width:100%;
        
        // background: #f1f1f1;
        // background: #56515126;

        // box-shadow: 0px 1px 2px rgba(84, 110, 122, 0.24), 0px 2px 4px rgba(120, 144, 156, 0.24);
        transition: all ease-in-out 0.5s;
        position:fixed;
        z-index: 115;
        margin-top:70px;
        // overflow-y: auto;
        .aside-inner {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: calc(100vh - 70px);
        }

        .scroll-btn {
            display: none;
        }

        nav {
            overflow: auto;
            flex: 1;
            padding: 0 1rem;
            scroll-behavior: smooth;

            @include devices(medium) {
                padding-top: 1.25rem;
            }

            @include devices(small) {
                padding-top: 0;
            }

            /* width */
            &::-webkit-scrollbar {
                width: 0.25rem;
            }
            
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f8f8f8;
            }
            
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c7c7c7;
            }
            
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #999;
            }
        }

        .nav-top {
            flex: 0 0 2.5rem;
            width: 100%;
            padding: 0 0.5rem;
            text-align: left;
            background: #FAFAFA66;
            border-bottom: 1px solid rgba(51, 51, 51, 0.125);
            margin-bottom: 1.25rem;
            color: #3339;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: .0005em;
            line-height: 14px;
            #closeBtn {
                display: none;
            }
           

            @include devices(medium) {
                display: none;
            }

            @include devices(small) {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 0;
                background: transparent;
                border: 0;

                #minimizeBtn {
                    display: none;
                   
                }

                #closeBtn {
                    display: block;
                    // background: #f5f5f5;
                    border: 0;
                    width: 100%;
                    height: 2.5rem;
                    padding: 0.5rem;
                    
                    svg {
                        margin: 0;
                    }
                }
            }
            
           
        }
        &:not(.collapsed) {
            background: rgba(207, 207, 207, 0.30);
            backdrop-filter: blur(5px);
            height:100%;
            #minimizeBtn {
                // width: 2.5rem;
                // height: 2.5rem;
                // background:white;
                // box-shadow: 0px 2px 6px 0px #0000001A;
                // border-radius:50%;
                // transform:translateY(-50%);
                margin:12px;
                color: #3339;
                font-size: 12px;
                font-weight: 600;
                letter-spacing: .0005em;
                line-height: 14px;
                display:flex;
                gap:8px;
                align-items: center;
                cursor: pointer;
            }

            .assignment-group-btn {
                align-items: flex-start;
            }
        }

        &.collapsed {
            // background: white;
            background:transparent;
            max-width: 7.125rem;
            border: 0;
            padding: 2.5rem 2rem 3rem 2rem;
            box-shadow: none;
            // max-height: calc(100vh - 5.5rem);
            .aside-inner {
                max-height: calc(100vh - 70px - 5.5rem);
                transition:max-height 0.3s ease;
                transition-delay: 0.15s;
            }

            .progress-indicator {
                width: 0;
                height: 0;
                padding: 0;
                overflow: hidden;
            }
            .assignment-group-container {
                background:transparent;
                border:none;
                margin:0;

                .assignment-group {
                    padding:0;
                }
                
            }
            .scroll-btn {
                display: block;
                border-bottom: 1px solid #eee !important; 
                border-radius: 0;

                &:last-child {
                    border: 0;
                    border-top: 1px solid #eee;
                    border-radius: 0 0 0.5rem 0.5rem;
                }
                &[disabled], &[hidden] {
                    display: none;
                }
                
            }
            .assignment-group-btn {
                display:none;
            }

            .aside-inner {
                border: 0.5px solid #B6B6B6;
                box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                background:#fff;
            }

            nav {
                overflow: hidden;
                padding: 0;
                overflow-y: auto;

                .assignment-group-container:last-child {
                    li:last-child .assignment-card {
                        // border: 0;
                        // border-top: 1px solid #eee;
                        border-radius: 0 0 0.5rem 0.5rem;

                        .item-status {
                            border-radius: 0 0 0.5rem 0.5rem
                        }
                    }
                }
                
                /* width */
                &::-webkit-scrollbar {
                    width: 0.0625rem;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #fff;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #fff;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #fff;
                }
            }
            .nav-top {
                padding: 0.25rem;
                gap: 0;
                background-color: transparent;
                margin: 0;
                border-bottom: 0.5px solid #33333340;
                ;
                .gr-btn {
                    color: #666666;
                    margin:0;
                    width:100%;
                    min-height:40px;
                    
                    span {
                        display: none;
                    }

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }

            /* Styles for assignment card are in _assignmentcard.scss */
        }

        &.closed {
            @include devices(medium) {
                height: 0;
                overflow: hidden;
                top: -10px;

                .aside-inner {
                    display: none;
                }
            }
            
        }

        @include devices(medium) {
            position: absolute;
            left: 0;
            z-index: 20;
        }

        @include devices(small) {
            position: absolute;
            left: 0;
            top: -3rem;
            z-index: 50;
            width: 100%;
            max-width: none;
            background: rgba(0,0,0,0.7);
            height: calc(100vh - 4rem);
        }
    }
    article {
        flex: 1;
        // height: 100%;
        // overflow: auto;

        .item-content {
            // max-height: 100%;
            // height: 100%;
            // overflow: auto;
            position: relative;
            z-index: 5;
            // padding-bottom: 3.5rem;

            > h3 {
                font-weight: 600;
                max-width: 46.25rem;
                margin: 2rem auto 0;
            }
            section:first-child {
                padding-top:70px;
            }
        }
        
        .player-footer {
            // height: 4.5rem;
            // background: linear-gradient(360deg, #F3F5F6 0%, rgba(255, 255, 255, 0.3) 100%);
            // background:#F3F5F6;
            // // border-top: 1px solid rgba(225, 228, 241, 0.5);
            // backdrop-filter: blur(7px);
            // border-radius:40px;
            // box-shadow: 0px 4px 15px 0px #0000001A;
            border-radius: 100px;
            background: var(--text-theme-dark, #FFF);
            box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap:36px;
            position: fixed;
            bottom: 2rem;
            right: 2rem;
            // left: 375px;
            z-index: 10;
            padding: 0.5rem;
            // border-top: 1px solid #eee;
            transition: left ease-in-out 0.5s;
            min-width:122px;

            .gr-btn.icon-btn-24 {
                width:2.25rem;
                height:2.25rem;
            }

            @include devices(medium) {
                // left: 0;
            }

            @include devices(small) {
                padding: 0.75rem 1rem;
            }

            &.full-width {
                left: 0;
            }

            &__left {
                display: flex;
                align-items: center;
            }

            &__right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}
.player-main.hide-header {
    aside.collapsed {
        margin-top:0;
        .aside-inner {
            
            max-height:calc(100vh - 5.5rem);
        }
    }
}
#playerBody .footer {display:none;}
.content-part {
    max-width: 49.25rem;
    margin: 2rem auto;
    padding: 5rem 1.5rem 0 1.5rem;

    &.full-width, &.iframe {
        max-width: none;
        // margin: 2rem;
        position: relative;
        min-height: calc(100vh);
        // height:100%;
        padding:0;
        margin:0;
        // overflow:hidden;
        
       

        iframe {
            border:0;
            overflow-x: hidden;
        }
    }
    &.applet {
        padding: 80px;
        // min-height: calc(100vh);
        iframe {
            max-width: 1100px;
            min-height:calc(100vh - 160px);
            margin: 0 auto;
            display: block;
        }
    }

    h3 {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: 2rem !important;
    }

    p {
        margin-bottom: 1rem;
    }
}
.video-player {
    width: 100%;
    height: auto;
    border: 1px solid #c7c7c7;
}
iframe {
    width: 100%;
    height:100%;
    // height: calc(100vh - 18rem);
    // border: 2px solid #c7c7c7;
}

.question-stem {
    margin-bottom: 1rem;
}

.math-large {
    font-size: 2.25rem;
    line-height: 1rem;
}

.answer-options {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;

    li {
        margin: 0 !important;
        padding: 0 !important;
    }

    li:marker {
         display: none;
    }
}

.answer {
    margin-bottom: 1.5rem;

    .small-input {
        width: 3rem;
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        font-size: 0.875rem;
        padding: 0.25rem;
    }
    .inline-select {
        border: 1px solid #c7c7c7;
        border-radius: 4px;
        display: inline-block;
        font-size: 0.875rem;
        padding: 0.25rem;
    }

    .small {
        font-size: 0.875rem;
    }
}

.answer-status {
    background-color: #f5faf5;
    border-radius: 4px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);

    .title {
        color: #038238;
        align-items: center;
        display: flex;
        font-size: 1rem;
        font-weight: 600;
        justify-content: space-between;
        padding: 0.75rem 1rem;
    }
}

/* Player footer actions that switch to dropdown in mobile view */
.mobile-dd-trigger {
    display:  none;

    @include devices(small) {
        display: inline-flex;
        gap: 0.5rem;
    }
}

.item-actions {
    display: flex;
    align-items: center; 

    @include devices(small) {
        display: none;
        flex-direction: column;
        background: #fff;
        border-radius: 0.5rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
        padding: 0.25rem 1rem;

        &.open {
            display: flex;
            position: absolute;
            bottom: 3.25rem;
            left: 1rem;

        }
    }

    .gr-btn {
        @include devices(small) {
            display: block;
            width: 100%;
            text-align: left;
        }
    }
}
