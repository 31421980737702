@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


// Variables
$breakpointS: 640px;

.bronte-body {
    --font-family-primary: 'Poppins', sans-serif;
    --horizontal-gap: 40px;
    --vertical-gap: 40px;
    --outside-padding: 80px;
    --text-color: rgba(0,0,0,0.8);
    --text-color-inverse: rgba(255,255,255,0.8);
   

}
.bronte-body * {
    font-family: var(--font-family-primary);
    // color: var(--text-color);
}

.bronte-body {
    position: relative;
    width: 100%;
    -webkit-box-align: center;
    align-items: center;
    padding: initial;
    margin: initial;
    z-index: initial;

    .stage-container {width: 100%;}
    .stage-box {width:100%;}
    .spf-rce-stage {width:100%;min-height:50vh;}
}
.spf-rce-section {
    width:100%;
    padding:0 var(--outside-padding);
}

// Carousel
.carousel-container {
    .sc-carousel__content-container {
        position: relative;
        display: flex;
        width: 100%;
        
        .sc-carousel__pages-window {
            overflow:hidden;
            flex:1;
            max-width:100%;

            .sc-carousel__pages-container {
                display:flex;
                flex-direction:row;
                transition:transform 0.3s ease;

                .slide-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    user-select: none;
                    flex-direction: column;
                    gap:20px;
                    width: 100%;
                    height: 100%;
                    flex:1;
                    //custom
                    // padding-top:35px;
                    // mix-blend-mode: overlay;
                    position: relative;
                    padding:20px;
                    border-radius: 25px;
                    border: 3px solid rgba(255, 255, 255, 0.40);
                    background: rgba(255, 255, 255, 0.30);
                    width:100%;
                    .slide-image {
                        // position:absolute;
                        width:70px;
                        min-height:70px;
                        border: 3px solid rgba(255, 255, 255, 1);
                        border-radius:50%;
                        top:0;
                        left:35px;
                    }
                    .caption {
                        
                    }
                }
            }
        }
    }
    &.image-carousel {
        .sc-carousel__content-container .sc-carousel__pages-window .sc-carousel__pages-container .slide-container .slide-image {
            // position:absolute;
            width:100%;
            max-width:400px;
            margin:auto;
            min-height:270px;
            border: 3px solid rgba(255, 255, 255, 1);
            border-radius:0;
            // top:0;
            // left:35px;
        }
    }

    .custom-arrow-container {
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        background-color: transparent;
        .custom-arrow {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            // border-color: var(--primary-color);
            border-style: solid;
            border-width: thin;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 100ms ease;
            cursor: pointer;
            // fill: var(--primary-color);
            //custom 
            
            border-color: #fff;
            fill: #5F6368;
            background-color: #fff;
            box-shadow: 0px 67px 19px 0px rgba(0, 0, 0, 0.00), 0px 43px 17px 0px rgba(0, 0, 0, 0.01), 0px 24px 14px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
        
        }
    }
    .custom-dots {
        display:flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        margin-top:20px;
        gap:20px;

        .sc-carousel-dot__container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 16px;
            width: 16px;
            margin: 2px;
        }

        .sc-carousel-dot__dot {
            border-radius: 50%;
            display: inline-block;
            opacity: 0.5;
            transition: opacity 100ms ease;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;
            height: 6px;
            width: 6px;
            //custom
            background-color: #fff;
            border: 1px solid #000000B2;

            &.sc-carousel-dot__dot_active {
                opacity: 1;
                width:8px;
                height:8px;
            }
        }
    }
}


.sticky-left {
    .spf-rce-row {
        align-items: flex-start;
        .spf-rce-column:first-of-type {
            position:sticky;
            top:70px; // bcs of header
        }
    }
}

.spf-rce-section-background-wrapper {
    margin: 0px auto;
    width: 100%;
    // min-width: 940px;
    max-width: 940px;
    padding: 60px;
    box-sizing: border-box;
    border-width: 0px;
    border-style: solid;
    border-color: rgb(224, 224, 224);
    border-radius: 0px;
    background-color: unset;
    background-position: 50% calc(50% - var(--scrollProgress));
    background-size: calc(110%);
}
.size-lg .spf-rce-section-background-wrapper {
    max-width:1200px;
}
.size-md .spf-rce-section-background-wrapper {
    //940
}
.size-sm .spf-rce-section-background-wrapper {
    max-width: 800px;
}
.size-xs .spf-rce-section-background-wrapper {
    max-width: 600px;
}
.spf-rce-section-content {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    background-color: unset;
    max-width:1200px;
    margin:auto;
}
.spf-rce-row {
    position: relative;
    display: flex;
    flex-direction:column;
    gap:var(--horizontal-gap);
    width: 100%;

    @media (min-width:641px) {
        flex-direction: row;
    }
}
.spf-rce-column {
    position: relative;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    min-width: 15px;
}

.tight-sections {
    padding-bottom:0;
    .spf-rce-section:not(.banner) {
        .spf-rce-section-background-wrapper {
            padding-bottom:0;
        }
    }
    
    + .spf-rce-section {
        padding-top:0;
        .spf-rce-section-background-wrapper {
            padding-top:20px;
        }
    }

    .spf-rce-section:last-of-type {
        .spf-rce-section-background-wrapper {
            padding-bottom:60px;
        }
    }
}

// Typography
/// Base
h1, h2, h3, h4, h5, h6, p {
    font-family: var(--font-family-primary);
    font-size:1rem;
    line-height:1.3;
    font-weight: normal;
    margin:0;
    color:rgba(0,0,0,0.8);

    white-space: pre-wrap;
    overflow-wrap: normal;
    // hyphens: auto;
    // -ms-hyphens: auto;
    // -webkit-hyphens: auto;
    // -moz-hyphens: auto;
}
a, span {
    font-size:inherit;
}
a {text-decoration:underline;}
a:hover, a:focus {text-decoration:none;}
a.external {
    color: var(--text-color);
    text-transform:uppercase;
    letter-spacing: 1px;
    padding: 0.75em 0;
    &:after {
        content: url("data:image/svg+xml,<svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.70117 21.9014C5.15117 21.9014 4.68034 21.7055 4.28867 21.3139C3.89701 20.9222 3.70117 20.4514 3.70117 19.9014V5.90137C3.70117 5.35137 3.89701 4.88053 4.28867 4.48887C4.68034 4.0972 5.15117 3.90137 5.70117 3.90137H12.7012V5.90137H5.70117V19.9014H19.7012V12.9014H21.7012V19.9014C21.7012 20.4514 21.5053 20.9222 21.1137 21.3139C20.722 21.7055 20.2512 21.9014 19.7012 21.9014H5.70117ZM10.4012 16.6014L9.00117 15.2014L18.3012 5.90137H14.7012V3.90137H21.7012V10.9014H19.7012V7.30137L10.4012 16.6014Z' fill='currentColor'/></svg>");
        display:inline-block;
        width:25px;
        height:25px;
        vertical-align: text-bottom;
        margin-left: 0.25em;
    }
}
strong {
    //what is extra bold
    font-weight:700;
}
p + p, p + ul, ul + p, .spf-element-text + .spf-element-text {
    margin-top:1rem;
}
.inline-icon {
    margin-right:8px;
    vertical-align: text-bottom;
}

img.inline-icon {
    width:40px;
    height:40px;
    display:inline-block;
    vertical-align: middle;
}
img.block-icon {
    width:100px;
    height:100px;
    display:block;
    margin:0 auto 1rem auto;
}
ul, ol {
    margin-left:2rem;
}
figure {
    margin:0;

    img {
        border-radius:24px;
        overflow:hidden;
    }
    figcaption {
        margin-top:2rem;
        margin-bottom:2rem;
    }
    &.knowdl {
         img {border-radius: 0;}
    }
}

//default
.spf-element-text {
    color: var(--text-color);
}
.spf-element-text .with-icon {
   > p:first-child {
        margin-left:40px;
        text-indent:-40px;
    }
    ul {
        margin-left: 60px;
    }
    // margin-left:60px;
    // text-indent: -60px;
}

.spf-element-plugin {
    iframe {
        background: none;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.scroll-down {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28.504px; /* 118.765% */
    letter-spacing: 1px;
    text-transform: uppercase;
}

.header-hero-xl h1,
.header-hero-xl h2,
.header-hero-xl h3,
.header-hero-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 5rem;
    line-height:1; //not sure about this
    letter-spacing: -2%;
    font-weight:400;
    margin:0;
    // word-break: break-word;

    @media (max-width: 640px) {
        font-size: 4rem;
    }
}

h1, //default h1
.header-h1-xl h1,
.header-h1-xl h2,
.header-h1-xl h3,
.header-h1-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 2.5rem;
    line-height: 130%;
    font-weight:400;
    margin-bottom: 1rem;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 2rem;
    }
}
.header-patient-encounter-xl h1,
.header-patient-encounter-xl h2,
.header-patient-encounter-xl h3,
.header-patient-encounter-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 2.5rem;
    line-height: 125%;
    font-weight: 500;
    margin:0;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 2rem;
    }
}
h2, //default h2
.header-h2-xl h1,
.header-h2-xl h2,
.header-h2-xl h3,
.header-h2-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    line-height: 122%;
    font-weight:600;
    margin-bottom: 1rem;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 1.75rem;
    }
}
h3, //default h3
.header-h3-xl h1,
.header-h3-xl h2,
.header-h3-xl h3,
.header-h3-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 1.875rem;
    line-height: 122%;
    font-weight:600;
    margin-bottom: 1rem;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 1.75rem;
    }
}
h4, //default h4
.header-h4-xl h1,
.header-h4-xl h2,
.header-h4-xl h3,
.header-h4-xl h4 {
    font-family: var(--font-family-primary);
    font-size: 1.75rem;
    line-height: 125%;
    font-weight:600;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 1.5rem;
    }
}
.header-subhead-xl h1,
.header-subhead-xl h2,
.header-subhead-xl h3,
.header-subhead-xl h4,
.header-subhead-xl p {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    line-height: 160%;
    font-weight:600;
    // word-break: break-word;
    @media (max-width: 640px) {
        font-size: 1.5rem;
    }
}
.header-hero-lg h1,
.header-hero-lg h2,
.header-hero-lg h3,
.header-hero-lg h4 {
    font-family: var(--font-family-primary);
    font-size: 4.25rem;
    line-height:54px; //not sure about this
    font-weight:400;
    letter-spacing: -2%;
    @media (max-width: 640px) {
        font-size: 3rem;
    }
}
.header-h1-lg h1,
.header-h1-lg h2,
.header-h1-lg h3,
.header-h1-lg h4 {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    line-height: 125%;
    font-weight:400;
    @media (max-width: 640px) {
        font-size: 2rem;
    }
}
.header-h2-lg h1,
.header-h2-lg h2,
.header-h2-lg h3,
.header-h2-lg h4 {
    font-family: var(--font-family-primary);
    font-size: 1.75rem;
    line-height: 122%;
    font-weight:600;
    @media (max-width: 640px) {
        font-size: 1.75rem;
    }
}
.consider-your-patient-heading h3 {
    font-family: var(--font-family-primary);
    font-size: 1.75rem;
    line-height: 122%;
    font-weight:400;
    @media (max-width: 640px) {
        font-size: 1.75rem;
    }
}
.header-patient-encounter-lg h1,
.header-patient-encounter-lg h2,
.header-patient-encounter-lg h3,
.header-patient-encounter-lg h4 {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    line-height: 125%;
    font-weight:500;
    margin-bottom:0;
    @media (max-width: 640px) {
        font-size: 1.875rem;
    }
}
//default h5, default h6 missing. These are placeholders
h5 {
    font-family: var(--font-family-primary);
    font-size: 1.5rem;
    line-height: 160%;
    font-weight: 500;
    @media (max-width: 640px) {
        font-size: 1.25rem;
    }
}
h6 {
    font-family: var(--font-family-primary);
    font-size: 1.125rem;
    line-height: 160%;
    font-weight: 500;
    @media (max-width: 640px) {
        // font-size: 3rem;
    }
}

.body-xl p { 
    font-family: var(--font-family-primary);
    font-size: 1.5rem;
    line-height: 160%;
    font-weight: 500;
}

p, .body-md p { //default text for Poppins
    font-family: var(--font-family-primary);
    font-size: 1.125rem;
    line-height: 160%;
    font-weight: 500;
}
.body-sm p, figcaption, figcaption p {
    font-family: var(--font-family-primary);
    font-size: 1rem;
    line-height: 160%;
    font-weight: 500;
}
.body-xl ol p, .body-xl ul p,
.body-md ol p, .body-md ul p ,
.body-sm ol p, .body-sm ul p {
    font-weight:600;
}

.label-xl-allcaps p {
    font-family: var(--font-family-primary);
    font-size: 2rem;
    line-height: 160%;
    font-weight: 600;
    text-transform:uppercase;
}
.label-xl p {
    font-family: var(--font-family-primary);
    font-size: 1.375rem;
    line-height: 136%;
    font-weight: 400;
}
.label-overline p, .label-overline h3 {
    font-family: var(--font-family-primary);
    font-size: 1.5rem;
    line-height: 160%;
    font-weight: 400;
    text-transform: uppercase;
    margin:0;
}
.label-lg p {
    font-family: var(--font-family-primary);
    font-size: 1.375rem;
    line-height: 136%;
    font-weight: 600;
}
.label-md p {
    font-family: var(--font-family-primary);
    font-size: 1rem;
    line-height: 150%;
    font-weight: 400;
}
.label-sm p {
    font-family: var(--font-family-primary);
    font-size: 0.875rem;
    line-height: 130%;
    font-weight: 400;
}
.label-xs p {
    font-family: var(--font-family-primary);
    font-size: 0.75rem;
    line-height: 133%;
    font-weight: 500;
}


.label-md p strong,
.label-sm p strong,
.label-xs p strong {
    font-weight:700; //regular bold
}


//need to also set pseudo class for line for overlines
.overline-xl p,
.overline-xl h1,
.overline-xl h2 {
    font-family: var(--font-family-primary);
    font-size: 1.25rem;
    line-height: 120%;
    font-weight: 700;
    text-transform:uppercase;
    margin:0;

}
.overline-xl {
    position:relative;
    display:flex;
    align-items: flex-start;
}
.overline-xl:before {
    content:'';
    width:64px;
    height:4px;
    margin-right:32px;
    background-color: var(--text-color);
    display:inline-block;
    margin-top:0.675em;
}
.overline-lg p {
    font-family: var(--font-family-primary);
    font-size: 1.125rem;
    line-height: 120%;
    font-weight: 700;
    text-transform:uppercase;
}
.overline-md p {
    font-family: var(--font-family-primary);
    font-size: 1rem;
    line-height: 120%;
    font-weight: 700;
    text-transform:uppercase;
}
.overline-sm p {
    font-family: var(--font-family-primary);
    font-size: 0.875rem;
    line-height: 120%;
    font-weight: 700;
    text-transform:uppercase;
}
.patient-encounter-chart-item p {
    font-family: var(--font-family-primary);
    font-size:2rem;
    font-weight:600;
    color:rgba(0, 0, 0, 0.60);
    letter-spacing: 1.6px;
    text-transform: uppercase;
    margin-bottom:1rem;
    margin-top:-0.5rem;
}
.scroll-down p{
    display:flex;
    align-items: center;
    gap:0.5rem;
}

.no-padding-section {
    .spf-rce-section-background-wrapper {
        padding-top:0;
        padding-bottom:0;
    }

    + .spf-rce-section .spf-rce-section-background-wrapper {
        padding-top:0;
    }
}

.list-box {
    ul, ol {margin:0;padding:0;list-style: none;}
    li {
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 0.75rem 1rem;

        + li {
            margin-top: 0.75rem;
        }
    }

    &.three-col-boxes ul {
        li {margin:0 !important}
        @media (min-width: 641px) {
            display:grid;
            grid-template-columns: 1fr 1fr;
            gap:1rem;
        }
        @media (min-width:769px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
.list-box-rows {
    .spf-rce-row {
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 0.75rem 1rem;
        align-items: center;
        margin:4px 0;
        
    }

    &.slide-in-bottom {

        .spf-rce-row {
            visibility: hidden;
            animation: slideInBottom 400ms forwards;
        }
        @for $i from 1 through 10 {
            .spf-rce-row:nth-child(#{$i}n) {
                animation-delay: #{$i * 0.25}s;
            }
        }
    }
   
}

//See if anything should get smaller for mobile


img {
    display:block;
    width:100%;
    max-width:100%;
}

// Grid


.white-box .spf-rce-section-content {
    border-radius: 30px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.52);
    backdrop-filter: blur(40px);
    padding: 40px;

    .aside {
        
        padding:20px;
        border-radius:20px;
        margin:20px 0;
        
        border: 1px solid var(--Stroke-Container, rgba(255, 255, 255, 0.80));
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%), rgba(255, 255, 255, 0.17);
        // background-blend-mode: color-dodge, lighten;
        box-shadow: -3px 7px 14px 0px rgba(0, 0, 0, 0.05);
    }
}
.white-box.video-box .spf-rce-section-content {
    padding:40px 0;
    overflow:hidden;
}
.two-col-boxes {
    .spf-rce-row {
        align-items: flex-start;
    }
    .spf-rce-column:not(:has(.spf-rce-spacer)) {
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 50px;
    }
    &.consider-your-patient-on-right {
        .spf-rce-column:last-of-type {
            padding:20px;
        }
    }
    
}
.three-col-boxes {
    .spf-rce-column:not(:has(.spf-rce-spacer)) {
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 1rem;
    }
    .spf-rce-row + .spf-rce-row {
        margin-top: var(--horizontal-gap);
    }
}
.content-box-right {
    .spf-rce-column:last-of-type {
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 40px;
    }
}
.content-box-left {
    .spf-rce-column:first-of-type {
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.40);
        background: rgba(255, 255, 255, 0.60);
        backdrop-filter: blur(20px);
        padding: 40px;
    }
}
.layout-two-one {
    .spf-rce-row {
        .spf-rce-column {flex:1}
        .spf-rce-column:first-of-type {flex:2;}
    }
    .spf-rce-row + .spf-rce-row {
        margin-top: var(--horizontal-gap);
    }
}
.layout-one-two {
    .spf-rce-row {
        .spf-rce-column {flex:1}
        .spf-rce-column:last-of-type {flex:2;}
    }
    .spf-rce-row + .spf-rce-row {
        margin-top: var(--horizontal-gap);
    }
}
.centered {
    text-align: center;
}
.icon-80 {
    max-width:80px;
    margin:auto;
}
.list-wrap ul {
    
    @media (min-width: 769px) {
        display:grid;
        grid-template-columns:1fr 1fr;
        grid-gap:1rem;
    }
}
.caption-box, .white-box .white-box {
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.40);
    background: rgba(255, 255, 255, 0.60);
    backdrop-filter: blur(20px);
    padding: 0.75rem 1rem;
}
.white-box .white-box {
    padding: 1.5rem;
}
.sidebar-emergency {
    .spf-rce-section-background-wrapper .spf-rce-section-content {
        max-width: 75%;
        margin:0;
    }
}
.feature-centered {
    text-align:center;
}
.alert-box {
    padding-bottom:0;
    .spf-rce-section-background-wrapper {
        padding-bottom:0;
    }
    + .spf-rce-section {
        padding-top:0;
        .spf-rce-section-background-wrapper {
            padding-top:20px;
        }
    }
}
.alert-box .spf-rce-section-content {
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.80);
    background: linear-gradient(0deg, var(--Container-Emergency, rgba(175, 46, 48, 0.50)) 0%, var(--Container-Emergency, rgba(175, 46, 48, 0.50)) 100%), rgba(60, 60, 60, 0.30);
    // background-blend-mode: normal, luminosity;
    backdrop-filter: blur(55px);
    --text-color: var(--text-color-inverse);
    padding: 40px;
    padding-left:90px !important;

    h2,h3,h4,p {color:var(--text-color)}
    
    &:before {
        content: url("data:image/svg+xml,%3Csvg width='62' height='60' viewBox='0 0 62 60' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='62' height='60' rx='30' fill='%23F3605D'/><rect x='1' y='1' width='60' height='58' rx='29' stroke='white' stroke-opacity='0.98' stroke-width='2'/><path d='M28.7295 35.2324L28.2578 13.5352H33.7158L33.2666 35.2324H28.7295ZM31.0205 46.4629C28.9316 46.4629 27.5391 45.1152 27.5391 43.1162C27.5391 41.0947 28.9316 39.7471 31.0205 39.7471C33.0869 39.7471 34.457 41.0947 34.457 43.1162C34.457 45.1152 33.0869 46.4629 31.0205 46.4629Z' fill='white'/></svg%3E ");
        display:block;
        width:62px;
        height:62px;
        position:absolute;
        left:20px;
        top:40px;
    }
}
.spf-rce-section.banner {
    border-radius: 0;
    border-top: 3px solid #FFF;
    border-bottom: 3px solid #FFF;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(125px);

    .spf-rce-column {
        gap: var(--vertical-gap);
    }
}

.screen.screen-padding {
    .stage-container {
        padding: 80px 0px; //header
    }
}



.screen.overview {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    // --text-color: var(--text-color-inverse);
    .banner .spf-rce-section-background-wrapper {
        // padding-top:0;
        // padding-bottom:0;
    }
    .spf-rce-section-background-wrapper {
        max-width:1200px;
        padding-top:2rem;
        padding-bottom:2rem;
    }
    .spf-rce-row {
        align-items: center;
        flex-direction:column;
    }
    .spf-element-text {
    //    color:rgba(255, 255, 255, 0.80);
    }
    

    @media (min-width: 769px) {
        .spf-rce-row {
            flex-direction:row;

            .spf-rce-column {
                max-width: 50%;
            }
            // just for now
            .spf-rce-column:last-of-type {
                position:absolute;
                height:100%;
                max-width:40%;
                right:0;
                top:50%;
                transform:translateY(-50%);
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        .image-xl {
            img {
                // transform:scale(1.5);
                transform-origin: left center;
            }
            // position:absolute;
            // transform: translateY(-50%) scale(1.5);
            // transform-origin: left;
            // top:0;
            // max-width:100%;
        }
    }

}
.screen.full-height {
    min-height:100vh;
}
.screen.lesson-opener {
    // min-height:100vh;
    .spf-rce-section-background-wrapper {
        padding: 120px 60px; //header

        // .spf-rce-row {
        //     align-items: flex-end;
        // }
    }

    .white-box {
        padding:0;

        @media (min-width: 641px) {
            .spf-rce-row {
                position:unset;
            }
            .spf-rce-column {
                min-height:100%;
            }
            .spf-rce-column:last-of-type {
                position:unset;
                .spf-element-image img {
                    position:absolute;
                    right:0;
                    bottom:0;
                    // bottom:-40px; //padding
                    height:100%;
                    width:50%;
                    object-fit: contain;
                    object-position: bottom;
                }
            }
           
        }
        
    }
}

.docked-image-left-right { //section
    .spf-rce-column:first-of-type { //image
        display:none
    }
    .spf-rce-column:last-of-type {
        display:none;
    }


    @media (min-width: 769px) {
        &.white-box .spf-rce-section-content {
            padding: 80px 100px;
        }
        .spf-rce-column:first-of-type { //image
            position:absolute;
            display: block;
            max-width:200px;
            left:0;
            top:0;
            transform:translateX(-100%);
            // height:100%;

        }
        .spf-rce-column:last-of-type {
            position: absolute;
            display: block;
            max-width:200px;
            right:0;
            bottom:0;
            transform:translateX(100%);
        }
    }
}

.docked-image-right { //section
    .spf-rce-column:last-of-type {
        display:none;
    }
    @media (min-width: 769px) {
        &.white-box .spf-rce-section-content {
            padding: 80px 100px;
        }
        .spf-rce-column:last-of-type {
            position: absolute;
            display: block;
            max-width:200px;
            right:0;
            top:0;
            transform:translateX(100%);

            .docked-right-top img {
                transform:scale(1.3) translateY(-10%); //just for prototype
            }
        }
    }
}

.docked-image {
    padding:0;
    position:absolute;
    bottom:0;
    overflow:hidden;
    pointer-events: none;
    max-height:100%;
    .spf-rce-section-background-wrapper {
        padding:0;
        max-width:100%;
    }
    .spf-rce-section-content {
        max-width:100%;
    }
    &.image-right {
        right:0;
    }
}


.screen.patient-encounter {
    padding: 250px 0;
    h1 {margin:0;}
    .spf-rce-stage {
        position: relative;
        max-width: 1080px;
        margin: auto;
        padding: 0 40px;
        background-image: url("./bg_ipad_middle.png");
        background-repeat: repeat-y;
        background-size: 100%;
        &:before {
            content: '';
            width: 100%;
            height: 250px;
            background-image: url("./bg_ipad_top.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: -250px;
        }
        &:after {
            content: '';
            width: 100%;
            height: 250px;
            background-image: url("./bg_ipad_bottom.png");
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -250px;
        }
    }

    .spf-rce-section-background-wrapper {
        padding: 10px 0;
    }
    .spf-rce-section-content {
        background:rgba(255, 255, 255, 0.40);
        border-radius:20px;
        padding:20px;
        
    }
   
}
.image-small {
    max-width:25%;
    margin:10px auto;
}
.video-placeholder {
    position:relative;

    &:after {
        content:'';
        width:70px;
        height:70px;
        display:block;
        background-image: url('./icon_video.svg');
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
    }
}
.patient-encounter-chart-item {
    position: relative;
    

    &.item-temperature {
        padding-left:90px;
        &:before {
            content:'';
            position:absolute;
            left:0;
            top:0;
            width:80px;
            height:80px;
            display:block;
            background-image: url(../images/influenza/icon_Temp.png);
            background-size: contain;
            background-repeat:no-repeat;
        }
    }
    &.item-heartrate {
        padding-left:90px;
        &:before {
            content:'';
            position:absolute;
            left:0;
            top:0;
            width:80px;
            height:80px;
            display:block;
            background-image: url(../images/influenza/icon_Heart.png);
            background-size: contain;
            background-repeat:no-repeat;
        }
    }
    
    h3 {
        font-family: var(--font-family-primary);
        font-size: 1.5rem;
        line-height: 160%;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom:0;
    }
}

.patient-encounter-section {
    &:last-child {
        padding-bottom:200px;
    }
    .spf-rce-section-background-wrapper {
        max-width:1200px;
    }
    .spf-rce-section-content {
        position: relative;
        // max-width: 1080px;
        margin: auto;
        padding: 0 60px;
        background-image: url("./bg_ipad_middle.png");
        background-repeat: repeat-y;
        background-size: 100%;

        .spf-rce-row {
            background:rgba(255, 255, 255, 0.60);
            border-radius:20px;
            padding:0;
            overflow:hidden;

            + .spf-rce-row {
                margin-top:20px;
            }
            .spf-element-text {
                padding:20px 30px 0 30px;

                &:only-child {
                    padding-bottom:20px;
                }

                + .spf-element-text {
                    padding-top:0;
                    // margin-top:-20px;
                }
            }
            figure img {
                border-radius:0;
            }
            figure.icon {
                padding: 20px;
            }
            .label-overline.with-icon {
                p:first-child, h3 {
                    margin-left: 60px;
                    text-indent: -60px;
                    svg {
                        // float:left;
                        vertical-align: middle;
                        margin-right:10px;
                    }
                }
            
            }
            .patient-encounter-chart-item.with-icon {
                margin-top:-1rem;
                p:first-child {
                    margin-left: 60px;
                    text-indent: 0;
                    &:has(svg) {
                        text-indent:-60px
                    }
                    svg {
                        // float:left;
                        vertical-align: middle;
                        margin-right:10px;
                    }
                }
            }
            .patient-encounter-prescription {
                // svg {vertical-align: middle;}

                h4:has(svg) {
                    + p {
                        margin-left:48px;
                    }
                }
            }
        }
        &:before {
            content: '';
            width: 100%;
            height: 250px;
            background-image: url("./bg_ipad_top.png");
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: -250px;
        }
        &:after {
            content: '';
            width: 100%;
            height: 250px;
            background-image: url("./bg_ipad_bottom.png");
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -250px;
        }
    }
    .patient-encounter-chart {
        padding-left:50px !important;
        position: relative;

        &:before {
            content:url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 60 60' fill='none'><path d='M25.6667 37.8L39.5333 37.8C41.4454 37.8 43 36.2454 43 34.3333L43 20.4667C43 18.5546 41.4454 17 39.5333 17L28.8354 17C27.9146 17 27.0317 17.3629 26.3817 18.0129L23.2129 21.1817C22.5629 21.8317 22.2 22.7146 22.2 23.6354L22.2 34.3333C22.2 36.2454 23.7546 37.8 25.6667 37.8ZM37.8 28.2667C37.8 28.7433 37.41 29.1333 36.9333 29.1333L34.3333 29.1333L34.3333 31.7333C34.3333 32.21 33.9433 32.6 33.4667 32.6L31.7333 32.6C31.2567 32.6 30.8667 32.21 30.8667 31.7333L30.8667 29.1333L28.2667 29.1333C27.79 29.1333 27.4 28.7433 27.4 28.2667L27.4 26.5333C27.4 26.0567 27.79 25.6667 28.2667 25.6667L30.8667 25.6667L30.8667 23.0667C30.8667 22.59 31.2567 22.2 31.7333 22.2L33.4667 22.2C33.9433 22.2 34.3333 22.59 34.3333 23.0667L34.3333 25.6667L36.9333 25.6667C37.41 25.6667 37.8 26.0567 37.8 26.5333L37.8 28.2667ZM19.6 26.9667C19.6 26.2462 19.0204 25.6667 18.3 25.6667C17.5796 25.6667 17 26.2462 17 26.9667L17 35.6333C17 39.7013 20.2988 43 24.3667 43L36.5 43C37.2204 43 37.8 42.4204 37.8 41.7C37.8 40.9796 37.2204 40.4 36.5 40.4L24.3667 40.4C21.7342 40.4 19.6 38.2658 19.6 35.6333L19.6 26.9667Z' fill='white'/></svg>");
            display:flex;
            align-items: center;
            justify-content: center;
            width:40px;
            height:40px;
            background-color: #344259;
            border-radius:50%;
            position:absolute;
            left:0px;
            top:0px;
        }
    }
    .patient-encounter-intro {
        position: relative;
        padding-left:50px !important;

        &:before {
            content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' fill='none'><path d='M17.9993 29.3333C15.5993 29.3333 13.5549 28.4888 11.866 26.8C10.1771 25.1111 9.33268 23.0666 9.33268 20.6666V19.9C7.42157 19.5888 5.83268 18.6944 4.56602 17.2166C3.29935 15.7388 2.66602 14 2.66602 12V3.99996H6.66602V2.66663H9.33268V7.99996H6.66602V6.66663H5.33268V12C5.33268 13.4666 5.8549 14.7222 6.89935 15.7666C7.94379 16.8111 9.19935 17.3333 10.666 17.3333C12.1327 17.3333 13.3882 16.8111 14.4327 15.7666C15.4771 14.7222 15.9993 13.4666 15.9993 12V6.66663H14.666V7.99996H11.9993V2.66663H14.666V3.99996H18.666V12C18.666 14 18.0327 15.7388 16.766 17.2166C15.4993 18.6944 13.9105 19.5888 11.9993 19.9V20.6666C11.9993 22.3333 12.5827 23.75 13.7493 24.9166C14.916 26.0833 16.3327 26.6666 17.9993 26.6666C19.666 26.6666 21.0827 26.0833 22.2493 24.9166C23.416 23.75 23.9993 22.3333 23.9993 20.6666V18.4333C23.2216 18.1666 22.5827 17.6888 22.0827 17C21.5827 16.3111 21.3327 15.5333 21.3327 14.6666C21.3327 13.5555 21.7216 12.6111 22.4993 11.8333C23.2771 11.0555 24.2216 10.6666 25.3327 10.6666C26.4438 10.6666 27.3882 11.0555 28.166 11.8333C28.9438 12.6111 29.3327 13.5555 29.3327 14.6666C29.3327 15.5333 29.0827 16.3111 28.5827 17C28.0827 17.6888 27.4438 18.1666 26.666 18.4333V20.6666C26.666 23.0666 25.8216 25.1111 24.1327 26.8C22.4438 28.4888 20.3993 29.3333 17.9993 29.3333ZM25.3327 16C25.7105 16 26.0271 15.8722 26.2827 15.6166C26.5382 15.3611 26.666 15.0444 26.666 14.6666C26.666 14.2888 26.5382 13.9722 26.2827 13.7166C26.0271 13.4611 25.7105 13.3333 25.3327 13.3333C24.9549 13.3333 24.6382 13.4611 24.3827 13.7166C24.1271 13.9722 23.9993 14.2888 23.9993 14.6666C23.9993 15.0444 24.1271 15.3611 24.3827 15.6166C24.6382 15.8722 24.9549 16 25.3327 16Z' fill='white'/></svg>");
            display:flex;
            align-items: center;
            justify-content: center;
            width:40px;
            height:40px;
            background-color: #6C5BD4;
            border-radius:50%;
            position:absolute;
            left:0px;
            top:0px;
        }
    }
    .spf-rce-showhide {
        margin-top:-20px;
        margin-bottom:-20px;
        margin-left:-30px;
        margin-right:-30px;
        // background:rgba(255, 255, 255, 0.40);
        // border:1px solid #fff;
        // border-radius:20px;
        // padding:20px;
        // margin: 10px 0;
        // padding:0;
        // margin:0;
        background:transparent;
        border:none;
        width: calc(100% + 60px);
    }
}
.aside.consider-your-patient {
    .spf-rce-section-content {
    //     border-radius: 46px;
    //     border: 1px solid var(--windows-stroke-glass-specular, rgba(255, 255, 255, 0.40));
    //     background: rgba(255, 255, 255, 0.70);
    //     background-blend-mode: luminosity;
    //     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
    //     backdrop-filter: blur(50px);
        // padding:20px;

    }
    .spf-element-image {
        margin-bottom:10px;

        + .spf-element-text {
            padding: 0 10px;
        }
    }
    &.content-box-right {
        .spf-rce-column:last-of-type {
            padding: 20px;
        }
    }
}
.aside.safety-alert { //this is a text box
    border-radius: 1rem;
    border: 4px solid #DB47464D !important;
    background: rgba(255, 255, 255, 0.50);
    // background-blend-mode: luminosity;
    backdrop-filter: blur(54.160186767578125px);
    padding:60px 40px 40px 60px !important;
    text-align:start;

    h3 {
        color: #DB4746;
    }
    &:before {
        content:'';
        background: url('./safety-alert.png') no-repeat;
        background-size:contain;
        display:block;
        width:100px;
        height:100px;
        position:absolute;
        left:-30px;
        top:-30px;
        z-index: 1;
    }
}
.patient-encounter-chart {
    .spf-rce-section-content {
        padding-left:80px !important;

        &:before {
            content:url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60' fill='none'><path d='M25.6667 37.8L39.5333 37.8C41.4454 37.8 43 36.2454 43 34.3333L43 20.4667C43 18.5546 41.4454 17 39.5333 17L28.8354 17C27.9146 17 27.0317 17.3629 26.3817 18.0129L23.2129 21.1817C22.5629 21.8317 22.2 22.7146 22.2 23.6354L22.2 34.3333C22.2 36.2454 23.7546 37.8 25.6667 37.8ZM37.8 28.2667C37.8 28.7433 37.41 29.1333 36.9333 29.1333L34.3333 29.1333L34.3333 31.7333C34.3333 32.21 33.9433 32.6 33.4667 32.6L31.7333 32.6C31.2567 32.6 30.8667 32.21 30.8667 31.7333L30.8667 29.1333L28.2667 29.1333C27.79 29.1333 27.4 28.7433 27.4 28.2667L27.4 26.5333C27.4 26.0567 27.79 25.6667 28.2667 25.6667L30.8667 25.6667L30.8667 23.0667C30.8667 22.59 31.2567 22.2 31.7333 22.2L33.4667 22.2C33.9433 22.2 34.3333 22.59 34.3333 23.0667L34.3333 25.6667L36.9333 25.6667C37.41 25.6667 37.8 26.0567 37.8 26.5333L37.8 28.2667ZM19.6 26.9667C19.6 26.2462 19.0204 25.6667 18.3 25.6667C17.5796 25.6667 17 26.2462 17 26.9667L17 35.6333C17 39.7013 20.2988 43 24.3667 43L36.5 43C37.2204 43 37.8 42.4204 37.8 41.7C37.8 40.9796 37.2204 40.4 36.5 40.4L24.3667 40.4C21.7342 40.4 19.6 38.2658 19.6 35.6333L19.6 26.9667Z' fill='white'/></svg>");
            display:flex;
            align-items: center;
            justify-content: center;
            width:60px;
            height:60px;
            background-color: #344259;
            border-radius:50%;
            position:absolute;
            left:10px;
            top:10px;
        }
    }
}
.patient-encounter-intro {
    .spf-rce-section-content {
        padding-left:80px !important;

        &:before {
            content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'><path d='M17.9993 29.3333C15.5993 29.3333 13.5549 28.4888 11.866 26.8C10.1771 25.1111 9.33268 23.0666 9.33268 20.6666V19.9C7.42157 19.5888 5.83268 18.6944 4.56602 17.2166C3.29935 15.7388 2.66602 14 2.66602 12V3.99996H6.66602V2.66663H9.33268V7.99996H6.66602V6.66663H5.33268V12C5.33268 13.4666 5.8549 14.7222 6.89935 15.7666C7.94379 16.8111 9.19935 17.3333 10.666 17.3333C12.1327 17.3333 13.3882 16.8111 14.4327 15.7666C15.4771 14.7222 15.9993 13.4666 15.9993 12V6.66663H14.666V7.99996H11.9993V2.66663H14.666V3.99996H18.666V12C18.666 14 18.0327 15.7388 16.766 17.2166C15.4993 18.6944 13.9105 19.5888 11.9993 19.9V20.6666C11.9993 22.3333 12.5827 23.75 13.7493 24.9166C14.916 26.0833 16.3327 26.6666 17.9993 26.6666C19.666 26.6666 21.0827 26.0833 22.2493 24.9166C23.416 23.75 23.9993 22.3333 23.9993 20.6666V18.4333C23.2216 18.1666 22.5827 17.6888 22.0827 17C21.5827 16.3111 21.3327 15.5333 21.3327 14.6666C21.3327 13.5555 21.7216 12.6111 22.4993 11.8333C23.2771 11.0555 24.2216 10.6666 25.3327 10.6666C26.4438 10.6666 27.3882 11.0555 28.166 11.8333C28.9438 12.6111 29.3327 13.5555 29.3327 14.6666C29.3327 15.5333 29.0827 16.3111 28.5827 17C28.0827 17.6888 27.4438 18.1666 26.666 18.4333V20.6666C26.666 23.0666 25.8216 25.1111 24.1327 26.8C22.4438 28.4888 20.3993 29.3333 17.9993 29.3333ZM25.3327 16C25.7105 16 26.0271 15.8722 26.2827 15.6166C26.5382 15.3611 26.666 15.0444 26.666 14.6666C26.666 14.2888 26.5382 13.9722 26.2827 13.7166C26.0271 13.4611 25.7105 13.3333 25.3327 13.3333C24.9549 13.3333 24.6382 13.4611 24.3827 13.7166C24.1271 13.9722 23.9993 14.2888 23.9993 14.6666C23.9993 15.0444 24.1271 15.3611 24.3827 15.6166C24.6382 15.8722 24.9549 16 25.3327 16Z' fill='white'/></svg>");
            display:flex;
            align-items: center;
            justify-content: center;
            width:60px;
            height:60px;
            background-color: #6C5BD4;
            border-radius:50%;
            position:absolute;
            left:10px;
            top:10px;
        }
    }
}


.spf-rce-showhide {
    background:rgba(255, 255, 255, 0.70);
    border:1px solid #fff;
    border-radius:20px;
    
    margin: 10px 0;
    font-size:1.1em;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    font-weight:600;

    .icon {
        background: rgba(255, 255, 255, 0.46);
        border: 1px solid var(--Stroke-Container, #FFF);
        border-radius: 50%;
        width:54px;
        height:54px;
        flex:none;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width:24px;
            height:24px;
        }
    }

    details {
        width:100%;
        summary {
            display:flex;
            flex-direction:row;
            justify-content: space-between;
            align-items: center;
            text-align:start;
            padding:20px;
            -webkit-appearance:none;
            .icon {display: flex;}

            .show-text {}
            .hide-text {display:none}
            
        }
        summary::-webkit-details-marker {

            display:none
        }
        &[open] {
            summary {
                border-bottom:1px solid #fff;
            .hide-text {display:block;}
            .show-text {display:none}

                .icon svg{
                    transform:rotate(180deg);
                }
            }
            

            .show-hide-body {
                padding:20px;
            }
        }
    }
    
}
.margin-bottom-10 {
    margin-bottom:10px;
}
.margin-bottom-20 {
    margin-bottom:20px;
}
.margin-top-20 {
    margin-top:20px;
}
.padding-10 {
    padding:10px;
}
.padding-20 {
    padding:20px;
}
.padding-top-0 {
    padding-top: 0 !important;
    .spf-rce-section-background-wrapper {
        padding-top: 0 !important;
    }
}
.check-list {
    h3 {
        font-size:1.25rem;
    }

    ul {
        list-style-type: none;
        padding-left:0;
        margin:0;
        li {
            position:relative;
            padding-left: 48px;
            line-height:24px;
            margin-bottom:0.5rem;
            &:before {
                display:inline-block;
                content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'><path d='M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z' fill='%23FEF7FF'/></svg>");
                position: absolute;
                    left: 0;  /* place the SVG at the start of the padding */
                    width: 24px;
                    height: 24px;
                    background-color: #1D1B2066;
                    border-radius:2px;
            }
        }
    }
}

@import "flipcards";
.spf-rce-flipcard {

    .cards-container {
        gap:20px;
        .card {
            min-height:250px;

            .front {
                .inner {
                    top:unset;
                    bottom:0;
                    background-color: #FFF;
                    transform:none;
                    text-align: start;
                    display:flex;
                    flex-direction:row;
                    align-items: flex-start;
                    justify-content: space-between;
                    border-bottom-left-radius:20px;
                    border-bottom-right-radius: 20px;
                    &:after {
                        content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'> <path d='M7 20V11C7 10.45 7.19583 9.97917 7.5875 9.5875C7.97917 9.19583 8.45 9 9 9H17.2L15.6 7.4L17 6L21 10L17 14L15.6 12.6L17.2 11H9V20H7Z' fill='white'/></svg>");
                        padding: 0px 8px;
                        border-radius: 6px;
                        background: #4E4E4E;
                    }
                }
            }
        }
    }
}


// Animations
@keyframes slideInRight {
    0% {
        transform: translate3d(100%, 0px, 0px);
        visibility: visible;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
        visibility: visible;
    }
}

@keyframes slideInLeft {
    0% {
        transform: translate3d(-100%, 0px, 0px);
        visibility: visible;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
    }
}

@keyframes slideInBottom {
    0% {
        transform: translate3d(0px, 100%, 0px);
        visibility: visible;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
        visibility: visible;
    }
}

@keyframes rotateIn {
    0% {
        transform: rotate3d(0, 0, 1, -200deg);
        opacity: 0;
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
        opacity: 1;
    }
}
@keyframes updown {
    0% {
      transform: translateY(-10%);
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-10%);
    }
  }

  .animation-bounce svg {
    animation: updown 2s ease 4;
  }
.trigger-slide-in-right {
    visibility: hidden;
}
.slide-in-right {
    animation-name: slideInRight;
    animation-duration: 400ms;
    animation-delay: 0ms;
    visibility: visible;
}
.trigger-slide-in-left {
    visibility: hidden;
}
.slide-in-left {
    animation-name: slideInLeft;
    animation-duration: 400ms;
    animation-delay: 0ms;
    visibility: visible;
}
.trigger-slide-in-bottom {
    visibility: hidden;
}
.slide-in-bottom {
    animation-name: slideInBottom;
    animation-duration: 400ms;
    animation-delay: 0ms;
    visibility: visible;
}
.rotate-in {
    animation-name: rotateIn;
    animation-duration: 400ms;
    animation-delay: 0ms;
    visibility: visible;
}
