.etext {
    display: block;
    width: 100vw;
    height: 100vh;
    background: #DDDEDF;
    position: relative;

    .etext__navbar {
        position: absolute;
        left: 0;
        width: 5.875rem;
        height: 100vh;
        background: #F7F9FD;
        box-shadow: 0px 1px 4px rgba(28, 34, 46, 0.05), 0px 2px 12px rgba(28, 34, 46, 0.06);
        border-radius: 0px 16px 16px 0px;
        padding: 2rem 1.5rem;        
        z-index: 10;

        > ul {
            margin: 2rem 0 0 0;
            list-style-type: none;

            li {
                margin-bottom: 1rem;
            }
        }

        .btn-close {
            bottom: 2rem;
            left: 1.5rem;
            position: absolute;
        }
    }

    .etext__header {
        position: fixed;
        top: 0;
        height: 4rem;
        width: 100vw;
        z-index: 5;
        /*grid-column: 2;
        grid-row: 1; */
        background: linear-gradient(180deg, #F6F9FD 78.12%, rgba(247, 249, 253, 0) 100%);
        display: flex;
        justify-content: flex-end;
        padding: 0.5rem 1.5rem;

        h1 {
            font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
            font-size: 0.875rem;
            font-weight: 700;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .user-id {
            background: #F6F9FD;
            border: 1px solid #ECF1FB;
            border-radius: 20px;    
            width: 2.5rem;
            height: 2.5rem;
            font-style: normal;
            font-weight: 700;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
    }

    .etext__body {
        height: 100vh;
        padding-left: 5.875rem;
        overflow: auto;
        
        .page {
            max-width: 64rem;
            background: #fff;
            margin: 0 auto;
            padding: 5rem 5rem 6rem;
        }
    }

    .etext__footer {
        /*grid-column: 2;
        grid-row: 3;*/
        position:fixed;
        bottom: 0;
        width: 100vw;
        height: 5.5rem;
        background: linear-gradient(180deg, #F6F9FD 38.64%, rgba(221, 222, 223, 0) 100%);
        transform: rotate(180deg);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 0.5rem;

        img {
            transform: rotate(180deg);
        }
    }
}

.bronte-content {
    --color-primary: #232323;
    --color-secondary: rgba(26, 26, 26, 0.80); /*#1a1a1a;*/
    --color-tertiary: rgba(26, 26, 26, 0.65);
    --color-disabled: rgba(0, 0, 0, 0.50);
    --gradient-1-strong: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
    --gradient-1-light:linear-gradient(74deg, #C6CDFF 0.66%, #DEF1F1 99.66%);
    --gradient-2-strong: linear-gradient(74deg, #207C9E 0.66%, #262699 99.66%);
    --gradient-2-light:linear-gradient(74deg, #CDF3FF 0.66%, #E9EBFF 99.66%);
    --gradient-3-light:linear-gradient(51deg, #CDF3FF 23.98%, #7D8DE9 142.8%);
    --gradient-4-light:linear-gradient(0deg, #CDF3FF -91.25%, #E9EBFF 100%);

    --Neutral-Normal-Content-Primary: #232323;
    --Neutral-Normal-Content-Tertiary: #1A1A1AA6;
    --Neutral-Normal-Background-Primary: #FFFFFF;
    --Neutral-Inverse-Content-Primary: #FFFFFF;
    --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
    --Neutral-Inverse-Background-Primary: #212121;
    --Theme-Accent-1-Lightest: #E9EBFF;
    --Theme-Accent-1-Lighter: #C6CDFF;
    --Theme-Accent-1-Light: #7D8DE9;
    --Theme-Accent-1-Base: #4858D4;
    --Theme-Accent-1-Dark: #3944BC;
    --Theme-Accent-1-Darker: #262699;
    --Theme-Accent-1-Darkest: #191966;
    --Theme-Accent-2-Lightest: #DEF1F1;
    --Theme-Accent-2-Lighter: #ACDEDA;
    --Theme-Accent-2-Light: #74C8C2;
    --Theme-Accent-2-Base: #009184;
    --Theme-Accent-2-Dark: #007567;
    --Theme-Accent-2-Darker: #00493B;
    --Theme-Accent-2-Darkest: #003329;
    --Theme-Accent-3-Lightest: #CDF3FF;
    --Theme-Accent-3-Lighter: #A8DCF3;
    --Theme-Accent-3-Light: #64A5C3;
    --Theme-Accent-3-Base: #207C9E;
    --Theme-Accent-3-Dark: #005A76;
    --Theme-Accent-3-Darker: #003248;
    --Theme-Accent-3-Darkest: #002333;
    --Neutral-Normal-Background-Primary: #FFFFFF;
    --Neutral-Inverse-Content-Primary: #FFFFFF;
    --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
    --System-Informative-Lightest: #F1F7FE;
    --System-Informative-Lighter: #E3EEF7;
    --System-Informative-Light: #9ACDEF;
    --System-Informative-Base: #0075B1;
    --System-Informative-Dark: #00639B;
    --System-Informative-Darker: #003F65;
    --System-Informative-Darkest: #041E2D;
    --System-Positive-Lightest: #EEF6F6;
    --System-Positive-Lighter: #E0F0F0;
    --System-Positive-Light: #9AC1C1;
    --System-Positive-Base: #318181;
    --System-Positive-Dark: #006161;
    --System-Positive-Darker: #024040;
    --System-Positive-Darkest: #0B1E1E;
    --System-Attention-Lightest: #FDFBE7;
    --System-Attention-Lighter: #F5F2DB;
    --System-Attention-Light: #D2BA98;
    --System-Attention-Base: #8D7041;
    --System-Attention-Dark: #6A5329;
    --System-Attention-Darker: #47371A;
    --System-Attention-Darkest: #21190D;
    --System-Negative-Lightest: #FFF6F5;
    --System-Negative-Lighter: #FBEBE9;
    --System-Negative-Light: #FFA39E;
    --System-Negative-Base: #BF4044;
    --System-Negative-Dark: #9F2D34;
    --System-Negative-Darker: #691C20;
    --System-Negative-Darkest: #311211;

    font-family: 'Poppins', sans-serif;
    font-size:100%;
    margin:0;
    padding:0;
    // background-color: #FFF;
}