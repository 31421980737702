.accordion {
    h4 {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #005D83; 
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 1rem;
        gap: 0.5rem;
        
        svg {
            transform: rotate(0);
            transition: transform linear 0.3s;
        }
    }
    .accordion-content {
        margin-bottom: 1rem;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all linear 0.3s;

        p {
            font-family: 'Merriweather';
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 1.5;
            letter-spacing: 0.0005em;
            margin-bottom: 0.5rem;
        }
    }

    &.open {
        h4 {
            svg {
                transform: rotate(90deg);
            }
        }
        .accordion-content {
            max-height: 50rem;
            opacity: 1;
        }
    }
}