.gr-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #6A7070;
    background: transparent;
    color: #6A7070;
    padding: 8px 24px;
    font-size: 1rem;
    font-weight:600;
    cursor: pointer;
    border-radius: 40px;
    position: relative;
    margin: 12px;
    line-height: 1.15;
    text-decoration: none;
    white-space: nowrap;
    

    &:hover {
        color: #252525;
        border: 1px solid #252525;
    }

    &:focus {
        outline: 1px solid #6A7070;
        box-shadow: none;
    }

    &.green {
        background:#007E53;
        border: 1px solid transparent ; /* #AE367E */
        color: white;
        &:hover, &:focus {
            background:#006B49;
        }
    }

    &.primary {
        //background: #AE367E /*linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%)*/;
        
        background: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
        border: 1px solid transparent ; /* #AE367E */
        color: white;
        min-height:40px;
    
        &:hover {
            color: white;
            background: linear-gradient(85deg, #4a2c9b 0%, #01776d 100%);
            // background:#8C2B66;
            // background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%) /* #8C2B66*/;
        }

        &:focus {
            outline: 1px dashed #eee;
            outline-offset: -4px;
            box-shadow: none;
        }

        &:disabled {
            // background: #AE367E;
            opacity: 0.5;
            color: white !important;
        }
    }

    &.attention {
        background-color: #252525;
        border: 1px solid #252525;
        color: #fff;
    
        &:hover {
            background-color: #000000;
        }

        &:focus {
            box-shadow: none;
            outline: 1px dashed #eee;
            outline-offset: -4px;
        }
    }

    &.small {
        min-width: 128px;
        padding: 7px 20px;
        font-size: 14px;
    
        &:focus {
            &:after {
                padding: 18px 21px;
            }
        }
    }

    &.extra-large {
        min-width: 152px;
        padding: 10px 24px;
        font-size: 16px;

        &:focus {
            &:after {
                padding: 22px 21px;
                border-radius: 24px;
            }
        }
    }
    
    &:disabled {
        background-color: #E9E9E9;
        color: #6A7070 !important;
        border: 0 !important;
        cursor: default !important;
    }

    &.icon-btn-24 {
        min-width: auto;
        padding: 0.5rem 0.75rem;
        border: 0;
        margin: 0;
        height: 3rem;
        width: 3rem;
        border-radius: 50%;

        &:focus {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }

        &:hover {      
            border-radius: 50%;
            box-shadow: none;      
            background-color: #f2f2f2;
        }
        
        &:active, .active {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }
        
        svg {
            fill: #6A7070;
        }

        &:disabled {
            background-color: transparent !important;

            svg {
                fill: #ccc;
            }
        }
    }
    
    &.icon-btn-18 {
        min-width: auto;
        padding: 0.5rem 0.75rem;
        border: 0;
        margin: 0;
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;

        &:focus {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }
    
        &:hover {      
            border-radius: 50%;
            box-shadow: none;      
            background-color: #f2f2f2;
        }
        
        &:active, .active {
            border-radius: 50%;
            box-shadow: none;
            background-color: rgba(4, 122, 156, 0.08);
        }

        &:disabled {
            background-color: transparent !important;

            svg {
                fill: #ccc;
            }
        }

        svg {
            fill: #6A7070;
        }
    }
    &.next-btn {
        background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #2128D9 100%);
        color:#fff;
        opacity:0.8;
        svg {fill:currentColor;}
        &:hover, &:focus {
            opacity: 1;
        }
    }

    &.no-border {
        border: 0;
    
        &:hover {
            border: 0;
        }

        &:focus {
            &:after {
                border-radius: 5px;
                min-width: auto;
                left: auto;
                top: auto;
                padding: 16px;
            }
        }
    }

    &.link-btn {
        border: 0;
        padding: 0;
        min-width: 0;
        color: #05112A;

        &:focus, &:active {
            box-shadow: none;
            outline: none;
        }
    }
}