.Resizer {
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;

    &:before {
        content: "\e5d3";
        font-family: "Material Icons";
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        height: 0.5rem;
        width: 3rem;
        border-radius: 0.5rem;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  
    &:hover {
        -webkit-transition: all 2s ease;
        transition: all 2s ease;
    }
   
    &.horizontal {
        height: 1px;
        background-color: #C2C7CC;
        cursor: row-resize;
        margin: 0.5rem 0;
    }
    
    &.disabled {
        cursor: not-allowed;
    
        &:hover {
            border-color: transparent;
        }
    }
}

.SplitPane {
    &.horizontal {
        position: static !important;
    }
}

.Pane {
    &.horizontal {
        padding: 20px;
        overflow: auto;
        
        &::-webkit-scrollbar {
            width: 6px;
        }
          
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
          
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #737373;
            border-radius: 4px;
        }
          
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #a3a5a7;
        }
    }

    &.Pane1 {
        flex: 1 1 60%;
    }

    &.Pane2 {
        flex: 1 1 40%;

        img {
            max-height: 400px;
        }
    }
}