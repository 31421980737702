/*wrapper*/
.spf-rce-flipcard {
.component-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    .component-label,.component-credit, .component-caption,.component-instruction {
      margin: 0 5px;
      padding: 5px 0;
      & > p {
        display: inline-block;
        margin: 0;
      }
    }
    .component-title > p{
        display: inline-block;
        margin: 0;
    }
  }
  /* cards */
  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  
    &.stepper {
      display: block;
    }
  }
  
  .title-font {
    font-family: var(--title-font, 'Open Sans', 'sans-serif');
  }
  
  .cards-wrapper {
    content-visibility: auto;
    // display: grid;
    justify-content: center;
    padding-bottom: 1.25em;
    height: 100%;
  
    &.stepper {
      grid-auto-flow: column;
      height: calc(100% - 60px) !important;
      justify-content: unset;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      padding-top: 1.5vh;
      padding-bottom: 1.25em;
    }
  
    &:focus {
      outline: 0;
    }
  }
  
  .cards-container {
    align-content: center;
    display: grid;
    grid-gap: 0.8%;
    grid-template-columns: repeat(2, 1fr);
    // grid-template-rows: repeat(var(--gridTemplateRows), 1fr);
    justify-content: center;
    margin: 0;
    padding:0;
    min-width: 100%;
    padding: 1% 2.2%;
    list-style: none;
  
    width: 100%;
  
    &:focus {
      outline: 0;
    }
  
    &.isLoading {
      opacity: 0;
      transition: opacity 0.6s ease-in;
    }
  
    &.stepper {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 4%;
      grid-template-columns: repeat(var(--stepper-repeat), 96%);
      
      width: var(--stepper-slide-panel-width, 400%);
      transform: translate3d(0, 0, 0);
      transition: transform 0.6s ease-out;
      padding-left: 2%;
      padding-right: 2%;
      align-content: unset;
      justify-content: unset;
    }
  
    .card {
      border-radius: calc(var(--card-radius, 20px) * 1%);
      cursor: pointer;
      flex: auto;
      min-width: 100%;
      position: relative;
      min-height:100px;
     
      &.hide {
        transition: opacity 1s ease-in-out;
        opacity: 0;
        filter: alpha(opacity=0);
        display: none;
      }
  
      &.show {
        display: block;
        opacity: 1;
        filter: alpha(opacity=1);
        transition: opacity 1s ease-in-out;
      }
  
      &:hover {
        content: '';
        animation: pulse 1.5s ease 0.2s infinite;
        border-radius: var(--card-radius, 20px);
      }
  
      &:focus {
        content: '';
        animation: pulse 1.5s ease 0.2s infinite;
        outline: 0;
        box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
      }
  
      &:focus-visible {
        /* Special note: If debugging tab order change outline:none|0 to a visible property */
        /* outline: 3px solid var(--primary-color-hover); */
        box-shadow: 0px 0px 0px 1px var(--primary-color-hover);
        outline: 2;
      }
    }
  
    .card-container:focus,
    .card:focus {
      box-shadow: 0px 0px 0px 2px var(--primary-color-hover);
      outline: 2;
    }
  
    .front,
    .back {
      background-position: center;
      border-radius: var(--card-radius, 20px);
      box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.3);
      color: rgb(130, 130, 130);
      font-size: var(--fluid-scale-font-size);
      min-height: 100%;
      min-width: 100%;
      object-position: 50% 50%;
      text-align: center;
  
      /* flipping & hide one side of the cards */
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
  
      &:focus {
        box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
        outline: 0;
      }
  
      &:focus-visible {
        outline: 0;
        box-shadow: 0px 0px 0px 1px rgba(14, 43, 232, 1);
      }
    }
  
    .front::after {
      border-radius: var(--card-radius, 20px);
      content: '';
      display: block;
      left: 0;
      opacity: 0.3;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
  
      &:hover {
        opacity: 0.1;
      }
    }
  
    .back {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
      color: #000;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  
    .back::after {
      &:hover {
        opacity: 0.3;
      }
    }
  
    .card-container {
      display: flex;
      flex-direction: column;
      border-radius: var(--card-radius, 20px);
      position: relative;
      transform-style: preserve-3d;
      padding: 0;
      list-style-type: none;
      height: 100%;
  
      width: 100%;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 1000px;
      perspective: 1000px;
  
      &.active {
  
        .front,
        .back {
          transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
        }
  
        .back {
          -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
        }
  
        .front {
          -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
        }
      }
  
      /* dynamically set sizes */
      .fill {
        -o-background-size: 100% auto !important;
        -moz-background-size: 100% auto !important;
        -webkit-background-size: 100% auto !important;
        background-size: 100% auto !important;
        object-fit: fill;
        background-position: center center !important;
      }
  
      .contain {
        -o-background-size: contain;
        -moz-background-size: contain;
        -webkit-background-size: contain;
        background-size: contain !important;
        object-fit: contain;
        background-position: center center !important;
      }
  
      .cover {
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover !important;
        object-fit: cover;
        background-position: top center !important;
      }
  
      &:focus {
        content: '';
        animation: pulse 1.5s ease 0.2s infinite;
        box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
        outline: 0;
      }
  
      &:focus-visible {
        /*  outline: 3px solid var(--primary-color-hover); */
        outline: 0;
        box-shadow: 0px 0px 0px 1px rgba(14, 43, 232, 1);
      }
    }
  
    .inner {
      box-sizing: border-box;
      perspective: inherit;
      transform: translateY(-50%) scale(0.94);
      left: 0;
      outline: 1px solid transparent;
      padding: 1rem;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: 2;
  
      p {
  
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: var(--title-font);
          margin-block-start: unset !important;
          margin-block-end: unset !important;
          margin-bottom: 0.8em;
          margin-top: 0.8em;
        }
  
        p {
          margin-block-start: unset !important;
          margin-block-end: unset !important;
  
        }
      }
    }
  
    .front .inner {
      p {
        font-size: var(--fluid-scale-font-size);
        margin-bottom: 1rem;
        position: relative;
      }
  
      span {
        color: rgba(255, 255, 255, 0.7);
        font-family: var(--body-font, 'Open Sans', 'sans-serif');
        font-weight: 300;
      }
    }
  }
}