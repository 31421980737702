@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-size: 100%;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, p, ul, li {
  margin: 0;
  padding: 0;
  color: #05112A;
}

h1 {
  font-size: 1.5rem;
  font-weight: 400;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

h3 {
  color: #3E4C59;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

h4 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5;
}
h4[role=button] {
  cursor: pointer;
}

h5 {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
}

h6 {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 1rem 0 0 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #333;
}

a {
  text-decoration: none;
  color: #007FA3;
}

#pe-icons-sprite {
  display: none;
}

svg {
  fill: currentColor;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.main {
  display: flex;
  max-width: 100%;
  align-items: stretch;
}

.wrapper {
  max-width: 80rem;
  padding: 0 4.5rem;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 0 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .wrapper {
    padding: 0 1rem;
  }
}

/* Input ----*/
.gr-input {
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  height: 36px;
  color: #252525;
  border: 1px solid #9AA5B1;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.gr-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-input:disabled {
  color: #C7C7C7;
  background-color: #E9E9E9;
}
.gr-input.search-input {
  border-radius: 99px;
  background: url(../images/icon_search.png) no-repeat 0.625rem center;
  padding-left: 2.5rem;
  font-size: 1rem;
  min-width: 19rem;
}

.sr-only {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

/* Checkbox ---*/
.gr-checkbox {
  position: relative;
}
.gr-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.gr-checkbox input[type=checkbox] ~ label {
  display: inline-block;
  line-height: 1.5;
  min-height: 24px;
  padding-left: 2.5em;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 14px;
}
.gr-checkbox input[type=checkbox] ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid #c7c7c7;
  background: white;
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gr-checkbox input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  top: -3px;
  position: relative;
  left: -3px;
  fill: #047A9C;
}
.gr-checkbox input[type=checkbox]:disabled ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid #c7c7c7;
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: #E9E9E9;
}
.gr-checkbox input[type=checkbox]:disabled ~ span svg {
  fill: #C7C7C7;
}
.gr-checkbox input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-checkbox input[type=checkbox]:checked ~ span svg {
  opacity: 1;
}

/* Breadcrumbs -----*/
.breadcrumnbs {
  display: flex;
  gap: 1.125rem;
  margin: 1rem 0 1.5rem;
}
.breadcrumnbs a {
  color: #3E4C59;
  text-decoration: none;
}
.breadcrumnbs a::after {
  content: "";
  width: 8px;
  height: 14px;
  display: inline-block;
  background: url(../images/icon_breadcrumb.png) no-repeat center center;
  margin-left: 1.125rem;
}
.breadcrumnbs a:last-child {
  color: #007FA3;
}
.breadcrumnbs a:last-child::after {
  display: none;
}
.breadcrumnbs a:hover {
  color: #007FA3;
}

.badge {
  display: inline-block;
  background: #CFD8DC;
  border-radius: 99px;
  font-weight: 400;
  font-size: 0.75rem;
  color: #263238;
  padding: 2px 6px;
  margin-left: 0.5rem;
}

.gr-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6A7070;
  background: transparent;
  color: #6A7070;
  padding: 8px 24px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 40px;
  position: relative;
  margin: 12px;
  line-height: 1.15;
  text-decoration: none;
  white-space: nowrap;
}
.gr-btn:hover {
  color: #252525;
  border: 1px solid #252525;
}
.gr-btn:focus {
  outline: 1px solid #6A7070;
  box-shadow: none;
}
.gr-btn.green {
  background: #007E53;
  border: 1px solid transparent; /* #AE367E */
  color: white;
}
.gr-btn.green:hover, .gr-btn.green:focus {
  background: #006B49;
}
.gr-btn.primary {
  background: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
  border: 1px solid transparent; /* #AE367E */
  color: white;
  min-height: 40px;
}
.gr-btn.primary:hover {
  color: white;
  background: linear-gradient(85deg, #4a2c9b 0%, #01776d 100%);
}
.gr-btn.primary:focus {
  outline: 1px dashed #eee;
  outline-offset: -4px;
  box-shadow: none;
}
.gr-btn.primary:disabled {
  opacity: 0.5;
  color: white !important;
}
.gr-btn.attention {
  background-color: #252525;
  border: 1px solid #252525;
  color: #fff;
}
.gr-btn.attention:hover {
  background-color: #000000;
}
.gr-btn.attention:focus {
  box-shadow: none;
  outline: 1px dashed #eee;
  outline-offset: -4px;
}
.gr-btn.small {
  min-width: 128px;
  padding: 7px 20px;
  font-size: 14px;
}
.gr-btn.small:focus:after {
  padding: 18px 21px;
}
.gr-btn.extra-large {
  min-width: 152px;
  padding: 10px 24px;
  font-size: 16px;
}
.gr-btn.extra-large:focus:after {
  padding: 22px 21px;
  border-radius: 24px;
}
.gr-btn:disabled {
  background-color: #E9E9E9;
  color: #6A7070 !important;
  border: 0 !important;
  cursor: default !important;
}
.gr-btn.icon-btn-24 {
  min-width: auto;
  padding: 0.5rem 0.75rem;
  border: 0;
  margin: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
.gr-btn.icon-btn-24:focus {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-24:hover {
  border-radius: 50%;
  box-shadow: none;
  background-color: #f2f2f2;
}
.gr-btn.icon-btn-24:active, .gr-btn.icon-btn-24 .active {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-24 svg {
  fill: #6A7070;
}
.gr-btn.icon-btn-24:disabled {
  background-color: transparent !important;
}
.gr-btn.icon-btn-24:disabled svg {
  fill: #ccc;
}
.gr-btn.icon-btn-18 {
  min-width: auto;
  padding: 0.5rem 0.75rem;
  border: 0;
  margin: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.gr-btn.icon-btn-18:focus {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-18:hover {
  border-radius: 50%;
  box-shadow: none;
  background-color: #f2f2f2;
}
.gr-btn.icon-btn-18:active, .gr-btn.icon-btn-18 .active {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-18:disabled {
  background-color: transparent !important;
}
.gr-btn.icon-btn-18:disabled svg {
  fill: #ccc;
}
.gr-btn.icon-btn-18 svg {
  fill: #6A7070;
}
.gr-btn.next-btn {
  background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #2128D9 100%);
  color: #fff;
  opacity: 0.8;
}
.gr-btn.next-btn svg {
  fill: currentColor;
}
.gr-btn.next-btn:hover, .gr-btn.next-btn:focus {
  opacity: 1;
}
.gr-btn.no-border {
  border: 0;
}
.gr-btn.no-border:hover {
  border: 0;
}
.gr-btn.no-border:focus:after {
  border-radius: 5px;
  min-width: auto;
  left: auto;
  top: auto;
  padding: 16px;
}
.gr-btn.link-btn {
  border: 0;
  padding: 0;
  min-width: 0;
  color: #05112A;
}
.gr-btn.link-btn:focus, .gr-btn.link-btn:active {
  box-shadow: none;
  outline: none;
}

.gr-radio {
  margin-bottom: 1.25rem;
  min-height: 1rem;
  position: relative;
  display: flex;
  align-items: center;
}
.gr-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}
.gr-radio input[type=radio]:focus ~ span {
  outline: 0;
}
.gr-radio input[type=radio] + label {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  padding-left: 36px;
  font-size: 1rem;
  cursor: pointer;
}
.gr-radio input[type=radio] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid #c7c7c7;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  color: #6a7070;
  display: block;
  height: 1.5rem;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.gr-radio input[type=radio] ~ span svg {
  opacity: 0;
}
.gr-radio input[type=radio]:checked ~ span {
  border-color: transparent;
}
.gr-radio input[type=radio]:checked ~ span svg.select-icon {
  opacity: 1;
  top: -8px;
  position: relative;
  left: 0px;
  fill: #047A9C;
}
.gr-radio input[type=radio]:disabled ~ span {
  border-color: #ddd;
  background-color: #f7f7f7;
}
.gr-radio input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0 0 0 3px;
}
.gr-radio input[type=checkbox] + label {
  display: inline-flex;
  align-items: center;
  line-height: 18px;
  padding-left: 40px;
  font-size: 1rem;
  cursor: pointer;
}
.gr-radio input[type=checkbox] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid #7E7F80;
  background: white;
  border-radius: 2px;
  box-sizing: content-box;
  color: #6a7070;
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1;
}
.gr-radio input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  position: absolute;
  left: -2px;
  top: -2px;
}
.gr-radio input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-radio input[type=checkbox]:checked ~ span svg.select-icon {
  opacity: 1;
  fill: #047A9C;
}
@media only screen and (max-width: 480px) {
  .gr-radio input[type=checkbox]:checked ~ span svg.select-icon {
    top: -3px;
  }
}
.gr-radio input[type=checkbox]:disabled ~ span {
  border-color: #ddd;
  background-color: #f7f7f7;
}
.gr-radio.selected input[type=radio]:checked ~ span svg.correct-icon, .gr-radio.selected input[type=checkbox]:checked ~ span svg.correct-icon {
  opacity: 1;
  top: -8px;
  position: relative;
  left: -1px;
  fill: #038238;
}
.gr-radio.selected input[type=radio]:checked ~ span svg.select-icon, .gr-radio.selected input[type=checkbox]:checked ~ span svg.select-icon {
  display: none;
}

/* Old Header styles -----
.global-header {
    height: 4rem;
    position: relative;
    padding: 0.975rem 0;

    > .wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .logo {
        width: 126px;
        height: 32px;
    }

    .meta{
        font-size: 0.875rem;
        line-height: 1.5;
        color: #505759;
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;

        @include devices(large) {
            margin-right: 0;
        }

        .gr-btn {
            margin-left: 0.5rem;

            @include devices(medium) {
                display: none;
            }
        }

        .user-profile {
            font-weight: 700;
            display: flex;
            align-items: center;
            margin-right: 1rem;

            @include devices(medium) {
                display: none;
                margin-right: 0;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 6px 0 6px;
                border-color: #252525 transparent transparent transparent;
                margin-left: 0.5rem;
            }        
        }

        .user-profile--mobile {
            display: none;

            span {
                background-color: #00655B;
                color: #fff;
                display: flex;
                text-transform: uppercase;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
            }

            @include devices(medium) {
                display: flex;
            }
        }
    }

    &.wide {
        .wrapper {
            max-width: 100%;
            padding: 0 1rem 0 2rem;
        }
    }
} */
header {
  grid-row: 1;
  grid-column: 1/span 12;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  justify-content: space-between;
  position: relative;
  max-height: 70px;
  background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.7));
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 12;
}
header.overview {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
header.overview + main {
  padding-top: 70px;
}
header .left, header .right {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
header .right label {
  font-size: 0.75rem;
  line-height: 1.5;
}
header .left {
  gap: 1.5rem;
}
header h1 {
  font-size: 20px;
  color: #020917;
  font-weight: 600;
  font-family: "Open Sans", Helvetica, sans-serif;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin: 0;
}
header .MuiSvgIcon-root {
  fill: #585858;
}
header .badge {
  width: 16px;
  height: 16px;
  border-radius: 85px;
  background-color: #C117BA;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 600;
}
header.show {
  animation: headerShow 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
header.hide {
  animation: headerHide 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes headerHide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
@keyframes headerShow {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.overview-hero {
  padding: 2.5rem 0;
  min-height: 16rem;
  z-index: 2;
}
@media only screen and (max-width: 480px) {
  .overview-hero {
    padding: 1rem 0;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned {
    min-height: 0;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned .meta-data, .overview-hero.pinned .overview-hero__right, .overview-hero.pinned .progress-indicator {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned .action-bar {
    grid-row: 2;
  }
}
.overview-hero .gradient {
  background: linear-gradient(80.83deg, rgba(232, 203, 192, 0.1) 33.76%, rgba(99, 111, 164, 0) 93.73%), linear-gradient(87.2deg, rgba(255, 255, 255, 0.9) 19.77%, rgba(255, 255, 255, 0) 99.48%), rgba(4, 122, 156, 0.4);
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 6;
}
.overview-hero .gradient:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(181.53deg, rgba(232, 203, 192, 0.8) -1.76%, rgba(99, 111, 164, 0.096) 98.7%);
  z-index: 5;
}
.overview-hero:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.95) 85%, rgba(255, 255, 255, 0.3) 100%);
  z-index: 2;
}
.overview-hero .wrapper {
  position: relative;
  z-index: 10;
}
.overview-hero .wrapper .card {
  padding: 1.5rem 1.25rem;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .overview-hero .wrapper .card {
    grid-template-columns: 100%;
    align-items: flex-start;
    gap: 1.25rem;
  }
}
.overview-hero__left {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
}
@media only screen and (max-width: 768px) {
  .overview-hero__left {
    margin-bottom: 0;
  }
}
.overview-hero__right {
  grid-column: 2;
  grid-row: 1/span 2;
}
@media only screen and (max-width: 768px) {
  .overview-hero__right {
    grid-row: 2;
    grid-column: 1;
  }
}
.overview-hero ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.overview-hero ul.meta-data {
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--text-color, #ffffff);
  flex-wrap: wrap;
  margin: 0 !important;
}
.overview-hero ul.meta-data a {
  font-weight: 600;
}
.overview-hero ul.meta-data li {
  color: inherit;
}
.overview-hero ul.meta-data li + li {
  margin-left: 0.75rem;
}
.overview-hero ul.meta-data .due-date {
  background: var(--Icons-Informativ, #009EBE);
  padding: 8px 16px;
  text-align: center;
  color: white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
}
.overview-hero ul.meta-data .label {
  font-weight: 600;
  margin-right: 0.25rem;
}
.overview-hero .assignment-type {
  font-size: 0.875rem;
  color: black;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.overview-hero .assignment-type:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #017899;
  margin-right: 0.5rem;
}
.overview-hero h1 {
  color: #232323;
  font-size: 64px;
  font-weight: 400;
  line-height: 83.2px;
  text-align: left;
}
.overview-hero h1 .assignment-chapter {
  display: block;
  font-size: 21px;
  font-weight: 700;
  line-height: 25.58px;
  letter-spacing: 3.7400000095px;
  text-align: left;
  margin-bottom: 35px;
  text-transform: uppercase;
  color: rgba(26, 26, 26, 0.8);
}
@media only screen and (max-width: 480px) {
  .overview-hero h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}
.overview-hero .action-bar {
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;
  margin-top: 1.25rem;
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar {
    grid-row: 3;
    gap: 1rem;
  }
}
.overview-hero .action-bar .gr-btn {
  margin: 0;
  padding: 16px 24px;
  border: 1px solid #FFF;
  background: var(--Background-Whtie, #FFF);
  -webkit-backdrop-filter: blur(27.9779777527px);
          backdrop-filter: blur(27.9779777527px);
  filter: drop-shadow(0px 4px 9px rgba(89, 89, 89, 0.1)) drop-shadow(0px 17px 17px rgba(89, 89, 89, 0.09)) drop-shadow(0px 39px 23px rgba(89, 89, 89, 0.05)) drop-shadow(0px 69px 27px rgba(89, 89, 89, 0.01)) drop-shadow(0px 107px 30px rgba(89, 89, 89, 0));
  color: var(--Text-Primary, #232323);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 1.695px;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar .gr-btn {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.progress-indicator {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.progress-indicator .progress-info {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
}
.progress-indicator .progress {
  width: 100%;
  min-width: 18rem;
  height: 9px;
  border-radius: 99px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .progress-indicator .progress {
    min-width: 12rem;
  }
}
.progress-indicator .progress span {
  display: block;
  left: 0;
  top: 0;
  height: 9px;
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.5);
}

.overall-score {
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--text-color, #ffffff);
}
.overall-score h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-color, #ffffff);
}
@media only screen and (max-width: 480px) {
  .overall-score h3 {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 480px) {
  .overall-score h3 + span {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1200px) {
  .overall-score {
    padding: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .overall-score {
    background-size: auto, auto;
  }
}
@media only screen and (max-width: 480px) {
  .overall-score {
    height: 8.25rem;
  }
}

.overview-main {
  background: #F5F5F5;
  background: linear-gradient(0deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.8509803922) 100%), url("../images/sample_bg2.jpg") no-repeat;
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: black;
  --text-color:black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.overview-main .overview-body {
  position: relative;
  z-index: 2;
}
.overview-main.lesson1, .overview-main.lesson1c {
  background: linear-gradient(180deg, #EFE5C4 0%, #BCFFD5 59.5%, #EFE5C4 100%);
}
.overview-main.lesson1:before, .overview-main.lesson1c:before {
  content: "";
  width: 100vw;
  min-height: 100vh;
  background: url("../images/overview_bg.png") no-repeat;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 30%);
          mask-image: linear-gradient(to top, transparent 0%, black 30%);
  background-position-y: -50px;
  background-size: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.overview-main.lesson1:after, .overview-main.lesson1c:after {
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.8509803922) 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.overview-main.lesson2 {
  background: url("../images/overview/lesson3-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson2:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson3, .overview-main.lesson3v1 {
  background: url("../images/overview/lesson2-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson3:before, .overview-main.lesson3v1:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson3:after, .overview-main.lesson3v1:after {
  width: 100%;
  height: 25vh;
  background: url("../images/overview/lesson3-bg.jpg") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
          mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.overview-main.lesson3v2 {
  background: url("../images/overview/lesson3v2-bg.png") no-repeat;
  background-attachment: fixed;
  background-size: 100%;
}
.overview-main.lesson3v2:before {
  width: 100%;
  height: 100%;
  background: url("../images/overview/lesson3-bg-top.png") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  background-size: contain;
  background-attachment: fixed;
  background-position: top center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.overview-main.lesson3v2:after {
  width: 100%;
  height: 100%;
  background: url("../images/overview/lesson3-bg-bottom.png") no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-attachment: fixed;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.overview-main.lesson3v2 .overview-hero .wrapper .card {
  background: transparent;
  padding: 0;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.overview-main.lesson4 {
  background: url("../images/overview/lesson4-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson4:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson4:after {
  width: 100%;
  height: 25vh;
  background: url("../images/overview/lesson4-bg.jpg") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
          mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.overview-body {
  padding: 2rem;
}
.overview-body .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}
@media only screen and (max-width: 768px) {
  .overview-body .wrapper {
    flex-direction: column;
    gap: 2rem;
  }
}
.overview-body .wrapper.card {
  background: rgba(255, 255, 255, 0.4);
  border: 3px solid rgba(255, 255, 255, 0.3019607843);
  -webkit-backdrop-filter: blur(110px);
          backdrop-filter: blur(110px);
  padding: 2.5rem;
  border-radius: 50px;
}
.overview-body .overview-body__left {
  flex: 3;
}
.overview-body .overview-body__left .assignment-group:first-child {
  margin-top: 0;
}
.overview-body .overview-body__left .assignment-card {
  background: rgba(255, 255, 255, 0.8);
  border: 0;
}
@media only screen and (max-width: 768px) {
  .overview-body .overview-body__left {
    width: 100%;
  }
}
.overview-body .overview-body__right {
  flex: 2;
}
.overview-body .overview-body__right .card {
  background: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 32px;
  border-radius: 16px;
  border: 1.383px solid rgba(255, 255, 255, 0.1);
}
.overview-body .overview-body__right .overall-score {
  margin-bottom: 40px;
}

.svg-item {
  position: relative;
  max-width: 8.25rem;
  animation: donutfade 1s;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
}
@media only screen and (max-width: 1200px) {
  .svg-item {
    max-width: 6.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .svg-item {
    max-width: 9.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .svg-item {
    max-width: 6.25rem;
  }
}
@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.svg-item .donut-ring {
  stroke: #fff;
  opacity: 1;
}
@media only screen and (max-width: 480px) {
  .svg-item .donut-ring {
    stroke-width: 0.875rem;
  }
}
.svg-item .donut-segment {
  transform-origin: center;
  animation: donut1 1s;
}
.svg-item .center-text {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1rem;
}
.svg-item .center-text .large {
  font-weight: 400;
  font-size: 2rem;
}
.svg-item .center-text .percent {
  font-size: 1.25rem;
}
@keyframes donut1 {
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: 236, 10;
  }
}

.assignment-objectives h4 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
.assignment-objectives ul, .assignment-objectives ol {
  margin: 0;
  margin-left: 1rem;
  padding-left: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .assignment-objectives ul, .assignment-objectives ol {
    margin-left: 1rem !important;
  }
}
.assignment-objectives ul li, .assignment-objectives ol li {
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.15px;
}
.assignment-objectives ul li + li, .assignment-objectives ol li + li {
  margin-top: 1rem;
}

ul.assignment-summary {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.assignment-summary li {
  padding: 0.5rem;
  text-transform: capitalize;
  letter-spacing: 0.15px;
}
ul.assignment-summary li:last-child {
  border: 0;
}
ul.assignment-summary li:before {
  content: "";
  display: inline-block;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  border-radius: 50%;
}
ul.assignment-summary li.activities:before {
  background-color: #9348D4;
}
ul.assignment-summary li.readings:before {
  background-color: #5066C5;
}
ul.assignment-summary li.videos:before {
  background: #647A00;
}
ul.assignment-summary li.questions:before {
  background: #753299;
}
ul.assignment-summary li.others::before {
  background: #297F94;
}

.back-to-top {
  background: #7075F7;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 13;
}
@media only screen and (max-width: 480px) {
  .back-to-top {
    bottom: 2rem;
  }
}
.back-to-top:hover {
  background: rgba(0, 127, 163, 0.7);
}

.assignment-card {
  background: #FFFFFF;
  min-height: 5rem;
  padding: 0.75rem 1rem 0.75rem 5rem;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #eee;
}
.assignment-card:focus {
  outline: 2px solid #007FA3;
  outline-offset: -5px;
}
.assignment-card .icon-holder {
  position: absolute;
  left: 1rem;
  top: 1rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment-card .icon-holder > svg {
  fill: white;
}
.assignment-card .icon-holder.video {
  background-color: #647A00;
}
.assignment-card .icon-holder.activity {
  background-color: #9348D4;
}
.assignment-card .icon-holder.other {
  background-color: #297F94;
}
.assignment-card .icon-holder.reading {
  background-color: #5066C5;
}
.assignment-card .icon-holder.question {
  background-color: #753299;
}
.assignment-card .item-title {
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.0005em;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  margin-right: 2.5rem;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.assignment-card .item-meta-data {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  align-items: center;
  margin: 0 !important;
  padding: 0;
}
.assignment-card .item-meta-data li + li {
  padding-left: 0.5rem;
}
.assignment-card .type-chip {
  font-size: 0.75rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 0 !important;
}
.assignment-card .item-time, .assignment-card .item-points {
  color: #333;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: center;
}
.assignment-card .item-time {
  white-space: nowrap;
  border-left: 1px solid rgba(0, 0, 0, 0.23);
}
.assignment-card .item-status {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment-card .item-status svg.status-not-started {
  fill: #fff;
}
.assignment-card .item-status svg.status-complete {
  fill: #038238;
}
.assignment-card.in-overview .item-title {
  font-size: 1rem;
}
.assignment-card.in-overview .item-status svg.status-not-started {
  display: none;
}
.assignment-card:hover {
  background-color: rgb(233, 233, 233);
}
.assignment-card:hover .item-title {
  text-decoration: underline;
}
.assignment-card:hover .item-status {
  opacity: 1;
}
.assignment-card[disabled] {
  background-color: #FAFAFA;
}
.assignment-card[disabled] .icon-holder {
  background-color: #888888 !important;
}
.assignment-card[disabled]:hover {
  cursor: default;
  background-color: rgba(250, 250, 250, 0.8784313725);
}
.assignment-card[disabled]:hover .item-title {
  text-decoration: none;
}
.assignment-card[disabled]:focus {
  outline: none;
}
.assignment-card[disabled] .item-status {
  background-color: #f0f0f0;
  opacity: 0.8;
}
.assignment-card[disabled] .item-status svg {
  fill: rgba(0, 0, 0, 0.2);
}
.assignment-card.selected .item-title, .assignment-card.selected .item-meta-data, .assignment-card.selected .item-time, .assignment-card.selected .item-points, .assignment-card.selected .type-chip {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}
.assignment-card.selected .icon-holder {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.assignment-card.selected:focus {
  outline-color: #fff;
}
.assignment-card.selected:hover {
  background-color: inherit;
}
.assignment-card.selected.video {
  background-color: #647A00;
}
.assignment-card.selected.video .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.video .item-status .status-not-started {
  fill: #7D9900;
}
.assignment-card.selected.video .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.video .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.activity {
  background-color: #9348D4;
}
.assignment-card.selected.activity .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.activity .item-status .status-not-started {
  fill: #9348D4;
}
.assignment-card.selected.activity .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.activity .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.other {
  background-color: #297F94;
}
.assignment-card.selected.other .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.other .item-status .status-not-started {
  fill: #297F94;
}
.assignment-card.selected.other .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.other .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.reading {
  background-color: #5066C5; /*#566BB7;*/
}
.assignment-card.selected.reading .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.reading .item-status .status-not-started {
  fill: #5066C5; /*#566BB7;*/
}
.assignment-card.selected.reading .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.reading .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.question {
  background-color: #753299;
}
.assignment-card.selected.question .item-status .status-not-started {
  fill: #7C03B5;
}
.assignment-card.selected.question .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.question .item-status .status-complete {
  fill: white;
}

/* Collapsed Nav styles */
.collapsed .assignment-group {
  margin: 0 !important;
  box-shadow: none;
  gap: 0;
}
.collapsed .assignment-group li:first-child .assignment-card, .collapsed .assignment-group li:last-child .assignment-card {
  border-radius: 0;
}
.collapsed .assignment-group li:first-child .assignment-card .item-status, .collapsed .assignment-group li:last-child .assignment-card .item-status {
  border-radius: 0;
}
.collapsed .assignment-group li .assignment-card {
  border-radius: 0;
  height: 3rem;
  border: 0;
  padding: 0.75rem 0.5rem 0.5rem;
  border-bottom: 1px solid #eee;
  min-height: 0;
}
.collapsed .assignment-group li .assignment-card .item-title, .collapsed .assignment-group li .assignment-card .type-chip, .collapsed .assignment-group li .assignment-card .item-time, .collapsed .assignment-group li .assignment-card .icon-holder, .collapsed .assignment-group li .assignment-card .item-points {
  display: none !important;
}
.collapsed .assignment-group li .assignment-card .item-status {
  grid-column: 1/span 7;
  width: 100%;
}
.collapsed .assignment-group li .assignment-card .item-status.completed {
  background-color: transparent;
}
.collapsed .assignment-group li .assignment-card:hover.video {
  background-color: rgba(100, 122, 0, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.activity {
  background-color: rgba(147, 72, 212, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.other {
  background-color: rgba(41, 127, 148, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.reading {
  background-color: rgba(80, 102, 197, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.question {
  background-color: rgba(117, 50, 153, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card.selected .item-status.completed {
  background-color: rgb(3, 130, 56);
}

.assignment-group {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 1rem 0;
}
.assignment-group.standalone {
  padding: 0;
}
.assignment-group.standalone .assignment-card {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 16px;
}
.assignment-group:first-child {
  margin-top: 0;
}
.assignment-group li .assignment-card {
  border-radius: 0.5rem;
}

.assignment-group-btn {
  background: transparent;
  min-height: 5rem;
  padding: 16px 32px;
  cursor: pointer;
  position: relative;
  border: 0;
  border-radius: 0.5rem;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  font-size: 1.125rem;
  text-align: left;
}
.assignment-group-btn .feature-image {
  width: 78px;
  height: 78px;
  overflow: hidden;
  border-radius: 8px;
}
.assignment-group-btn .feature-image img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.assignment-group-btn .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.8);
}
.assignment-group-btn .icon svg {
  fill: #546E7A;
  transition: transform 0.15s ease-in-out;
}
.assignment-group-btn .assignment-info {
  flex: 1;
}
.assignment-group-btn .group-title {
  display: block;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.assignment-group-btn .item-meta-data {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style-type: none;
  align-items: flex-start;
  margin: 0 !important;
  padding: 0;
}
.assignment-group-btn .item-meta-data li {
  font-size: 0.825rem;
}
.assignment-group-btn .item-meta-data li:first-child {
  padding-left: 0;
}
.assignment-group-btn .item-meta-data .item-progress {
  display: flex;
  align-items: center;
  gap: 8px;
}
.assignment-group-btn .item-meta-data .progress-bar-label {
  white-space: nowrap;
}
.assignment-group-btn .item-meta-data .progress-bar {
  width: 100%;
  height: 6px;
  min-width: 114px;
  position: relative;
  background-color: white;
  border-radius: 4px;
}
.assignment-group-btn .item-meta-data .progress-bar .bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  background-color: #009EBE;
  border-radius: 4px;
}
.assignment-group-btn .expand-btn {
  background: transparent;
  border: none;
  cursor: pointer;
}
.assignment-group-btn .expand-btn:hover .icon {
  border: 1px solid rgba(84, 84, 85, 0.4);
}
.assignment-group-btn .expand-btn[aria-expanded=true] svg {
  transform: rotate(180deg);
}
.assignment-group-btn[aria-expanded=true] {
  border-radius: 0.5rem 0.5rem 0 0;
}
.assignment-group-btn[aria-expanded=true] .text {
  font-weight: bold;
}
.assignment-group-btn[aria-expanded=true] .icon svg {
  transform: rotate(180deg);
}

.assignment-group-container {
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  margin: 1rem 0;
}
.assignment-group-container:first-child {
  margin-top: 0;
}
.assignment-group-container .assignment-group {
  padding: 16px 32px;
  margin: 0;
  box-shadow: none;
  animation: 0.1s hide forwards;
}
.assignment-group-container .assignment-group.closed {
  padding: 0;
  animation: 0.1s hide forwards;
}
.assignment-group-container .assignment-group.open {
  animation: 0.6s hide;
  animation-delay: 0.3s;
}
.assignment-group-container .assignment-group .assignment-card {
  padding: 0.75rem 1rem;
}
.assignment-group-container .assignment-group .assignment-card .icon-holder {
  display: none;
}
.card-btn {
  background: transparent;
  border: none;
  color: #604BCE;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 0 8px;
  cursor: pointer;
}
.card-btn:hover {
  text-decoration: underline;
}

@keyframes hide {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
  }
}
.accordion h4 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #005D83;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1rem;
  gap: 0.5rem;
}
.accordion h4 svg {
  transform: rotate(0);
  transition: transform linear 0.3s;
}
.accordion .accordion-content {
  margin-bottom: 1rem;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all linear 0.3s;
}
.accordion .accordion-content p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  letter-spacing: 0.0005em;
  margin-bottom: 0.5rem;
}
.accordion.open h4 svg {
  transform: rotate(90deg);
}
.accordion.open .accordion-content {
  max-height: 50rem;
  opacity: 1;
}

.tippy-box {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
  border-radius: 4px;
  background-color: #546E7A;
  color: #fff;
  padding: 4px 8px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}
.tippy-box[data-placement^=bottom] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent #546E7A transparent;
  top: -19px;
}
.tippy-box[data-placement^=top] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: #546E7A transparent transparent transparent;
  top: 100%;
}
.tippy-box[data-placement^=left] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent transparent #546E7A;
  top: 50%;
  left: 100%;
}
.tippy-box[data-placement^=right] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent #546E7A transparent transparent;
  top: 50%;
  right: 100%;
  transform: translateY(50%);
}
.tippy-box.assignment-tip {
  background-color: #F7F8FC;
  margin-top: -3px;
}
.tippy-box.assignment-tip .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #f7f8fc transparent;
  top: -22px;
}
.tippy-box.assignment-tip .assignment-tip__content {
  background-color: #F7F8FC;
  color: #1F2933;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  padding: 1rem;
}
.tippy-box.assignment-tip .assignment-tip__content .details-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}
.tippy-box.assignment-tip .assignment-tip__content > div {
  margin: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.player-header {
  background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.7));
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1019607843);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 2rem;
  align-items: center;
  color: #000;
  z-index: 30;
  font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 112;
}
@media only screen and (max-width: 1200px) {
  .player-header {
    padding: 1rem 0.5rem 1rem 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .player-header {
    height: 3rem;
  }
}
.player-header .assignment-type {
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .player-header .assignment-type .assignment-type__wide {
    display: none;
  }
}
.player-header .assignment-type .assignment-type__mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .player-header .assignment-type .assignment-type__mobile {
    display: inline-block;
  }
}
.player-header .assignment-title {
  max-width: 35ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.player-header .progress-indicator {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.player-header .progress-indicator .progress-info {
  white-space: nowrap;
}
.player-header .progress-indicator .progress {
  height: 9px;
  background: rgb(231, 231, 231);
  padding: 0;
}
.player-header .progress-indicator .progress span {
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.3s ease-in;
  background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #7075F7 100%);
}
.player-header .gr-btn.icon-btn-18 svg, .player-header .gr-btn.icon-btn-24 svg {
  fill: #000;
}
.player-header .gr-btn.btn-close {
  margin-left: 1rem;
  padding: 8px 20px;
  background-color: #03313E;
  border: 0;
  color: #fff;
  font-size: 1rem;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close {
    margin: 0;
  }
}
.player-header .gr-btn.btn-close .mobile-view {
  display: none;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close .mobile-view {
    display: inline;
  }
}
.player-header .gr-btn.btn-close .desktop-view {
  display: inline;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close .desktop-view {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close {
    padding: 0.5rem;
    background: transparent;
  }
}
.player-header .player-header__left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__left #info-icon {
    display: none;
  }
}
.player-header .player-header__left .hamburger {
  display: none;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: transparent;
  border: none;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__left .hamburger {
    display: block;
  }
}
.player-header .player-header__left .hamburger > span {
  display: block;
  width: 1.5rem;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #151515;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.player-header .player-header__left .hamburger > span:first-child {
  transform-origin: 0% 0%;
}
.player-header .player-header__left .hamburger > span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.player-header .player-header__left .hamburger.open > span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  transform-origin: 50% 50%;
  margin: 0;
}
.player-header .player-header__left .hamburger.open > span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.player-header .player-header__left .hamburger.open > span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
  transform-origin: 50% 50%;
}
.player-header .player-header__center {
  text-align: center;
  transition: left linear 0.3s;
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__center {
    left: 42%;
  }
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__center {
    display: none;
  }
}
.player-header .player-header__center .item-type {
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 600;
}
.player-header .player-header__center .step-info {
  font-weight: 400;
  font-size: 0.875rem;
}
.player-header .player-header__right {
  display: flex;
  align-items: center;
  gap: 8px;
}
.player-header .player-header__right .mobile-score-btn {
  display: none;
  margin: 0;
}
.player-header .player-header__right .mobile-score-btn svg {
  fill: none !important;
  stroke: white;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .mobile-score-btn {
    display: block;
  }
  .player-header .player-header__right .mobile-score-btn:hover {
    background-color: transparent;
  }
  .player-header .player-header__right .mobile-score-btn:active, .player-header .player-header__right .mobile-score-btn:focus {
    outline: 0;
    border: 0;
  }
}
.player-header .player-header__right .score-info > span {
  display: inline-block;
  margin: 0 0.25rem;
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__right .score-info > span {
    margin: 0 0.125rem;
  }
}
.player-header .player-header__right .score-info > span.label {
  font-weight: 600;
}
.player-header .player-header__right .score-info > span.inline-val {
  font-weight: 300;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .score-info > span.divider {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__right .score-info > span {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .score-info {
    display: none;
    min-width: 225px;
    position: absolute;
    top: calc(100% + 0.125rem);
    right: 7.25rem;
    color: #333;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }
  .player-header .player-header__right .score-info:before, .player-header .player-header__right .score-info:after {
    bottom: 99%;
    right: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .player-header .player-header__right .score-info:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-right: 0px;
  }
  .player-header .player-header__right .score-info:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #c7c7c7;
    border-width: 12px;
    margin-right: -2px;
  }
  .player-header .player-header__right .score-info.open {
    display: grid;
    grid-template-columns: 50% auto;
  }
}
@media only screen and (max-width: 480px) {
  .player-header .player-header__right .score-info {
    right: 3.25rem;
  }
}

.player-main {
  display: flex;
  position: relative;
  background: #FFF;
}
.player-main aside {
  max-width: 375px;
  width: 100%;
  transition: all ease-in-out 0.5s;
  position: fixed;
  z-index: 115;
  margin-top: 70px;
}
.player-main aside .aside-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 70px);
}
.player-main aside .scroll-btn {
  display: none;
}
.player-main aside nav {
  overflow: auto;
  flex: 1;
  padding: 0 1rem;
  scroll-behavior: smooth;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (max-width: 768px) {
  .player-main aside nav {
    padding-top: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside nav {
    padding-top: 0;
  }
}
.player-main aside nav::-webkit-scrollbar {
  width: 0.25rem;
}
.player-main aside nav::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.player-main aside nav::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}
.player-main aside nav::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.player-main aside .nav-top {
  flex: 0 0 2.5rem;
  width: 100%;
  padding: 0 0.5rem;
  text-align: left;
  background: rgba(250, 250, 250, 0.4);
  border-bottom: 1px solid rgba(51, 51, 51, 0.125);
  margin-bottom: 1.25rem;
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.0005em;
  line-height: 14px;
}
.player-main aside .nav-top #closeBtn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .player-main aside .nav-top {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside .nav-top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    background: transparent;
    border: 0;
  }
  .player-main aside .nav-top #minimizeBtn {
    display: none;
  }
  .player-main aside .nav-top #closeBtn {
    display: block;
    border: 0;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
  }
  .player-main aside .nav-top #closeBtn svg {
    margin: 0;
  }
}
.player-main aside:not(.collapsed) {
  background: rgba(207, 207, 207, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 100%;
}
.player-main aside:not(.collapsed) #minimizeBtn {
  margin: 12px;
  color: rgba(51, 51, 51, 0.6);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.0005em;
  line-height: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
.player-main aside:not(.collapsed) .assignment-group-btn {
  align-items: flex-start;
}
.player-main aside.collapsed {
  background: transparent;
  max-width: 7.125rem;
  border: 0;
  padding: 2.5rem 2rem 3rem 2rem;
  box-shadow: none;
  /* Styles for assignment card are in _assignmentcard.scss */
}
.player-main aside.collapsed .aside-inner {
  max-height: calc(100vh - 70px - 5.5rem);
  transition: max-height 0.3s ease;
  transition-delay: 0.15s;
}
.player-main aside.collapsed .progress-indicator {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.player-main aside.collapsed .assignment-group-container {
  background: transparent;
  border: none;
  margin: 0;
}
.player-main aside.collapsed .assignment-group-container .assignment-group {
  padding: 0;
}
.player-main aside.collapsed .scroll-btn {
  display: block;
  border-bottom: 1px solid #eee !important;
  border-radius: 0;
}
.player-main aside.collapsed .scroll-btn:last-child {
  border: 0;
  border-top: 1px solid #eee;
  border-radius: 0 0 0.5rem 0.5rem;
}
.player-main aside.collapsed .scroll-btn[disabled], .player-main aside.collapsed .scroll-btn[hidden] {
  display: none;
}
.player-main aside.collapsed .assignment-group-btn {
  display: none;
}
.player-main aside.collapsed .aside-inner {
  border: 0.5px solid #B6B6B6;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
}
.player-main aside.collapsed nav {
  overflow: hidden;
  padding: 0;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.player-main aside.collapsed nav .assignment-group-container:last-child li:last-child .assignment-card {
  border-radius: 0 0 0.5rem 0.5rem;
}
.player-main aside.collapsed nav .assignment-group-container:last-child li:last-child .assignment-card .item-status {
  border-radius: 0 0 0.5rem 0.5rem;
}
.player-main aside.collapsed nav::-webkit-scrollbar {
  width: 0.0625rem;
}
.player-main aside.collapsed nav::-webkit-scrollbar-track {
  background: #fff;
}
.player-main aside.collapsed nav::-webkit-scrollbar-thumb {
  background: #fff;
}
.player-main aside.collapsed nav::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.player-main aside.collapsed .nav-top {
  padding: 0.25rem;
  gap: 0;
  background-color: transparent;
  margin: 0;
  border-bottom: 0.5px solid rgba(51, 51, 51, 0.2509803922);
}
.player-main aside.collapsed .nav-top .gr-btn {
  color: #666666;
  margin: 0;
  width: 100%;
  min-height: 40px;
}
.player-main aside.collapsed .nav-top .gr-btn span {
  display: none;
}
.player-main aside.collapsed .nav-top .gr-btn svg {
  transform: rotate(180deg);
}
@media only screen and (max-width: 768px) {
  .player-main aside.closed {
    height: 0;
    overflow: hidden;
    top: -10px;
  }
  .player-main aside.closed .aside-inner {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .player-main aside {
    position: absolute;
    left: 0;
    z-index: 20;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside {
    position: absolute;
    left: 0;
    top: -3rem;
    z-index: 50;
    width: 100%;
    max-width: none;
    background: rgba(0, 0, 0, 0.7);
    height: calc(100vh - 4rem);
  }
}
.player-main article {
  flex: 1;
}
.player-main article .item-content {
  position: relative;
  z-index: 5;
}
.player-main article .item-content > h3 {
  font-weight: 600;
  max-width: 46.25rem;
  margin: 2rem auto 0;
}
.player-main article .item-content section:first-child {
  padding-top: 70px;
}
.player-main article .player-footer {
  border-radius: 100px;
  background: var(--text-theme-dark, #FFF);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 10;
  padding: 0.5rem;
  transition: left ease-in-out 0.5s;
  min-width: 122px;
}
.player-main article .player-footer .gr-btn.icon-btn-24 {
  width: 2.25rem;
  height: 2.25rem;
}
@media only screen and (max-width: 480px) {
  .player-main article .player-footer {
    padding: 0.75rem 1rem;
  }
}
.player-main article .player-footer.full-width {
  left: 0;
}
.player-main article .player-footer__left {
  display: flex;
  align-items: center;
}
.player-main article .player-footer__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.player-main.hide-header aside.collapsed {
  margin-top: 0;
}
.player-main.hide-header aside.collapsed .aside-inner {
  max-height: calc(100vh - 5.5rem);
}

#playerBody .footer {
  display: none;
}

.content-part {
  max-width: 49.25rem;
  margin: 2rem auto;
  padding: 5rem 1.5rem 0 1.5rem;
}
.content-part.full-width, .content-part.iframe {
  max-width: none;
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
.content-part.full-width iframe, .content-part.iframe iframe {
  border: 0;
  overflow-x: hidden;
}
.content-part.applet {
  padding: 80px;
}
.content-part.applet iframe {
  max-width: 1100px;
  min-height: calc(100vh - 160px);
  margin: 0 auto;
  display: block;
}
.content-part h3 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2rem !important;
}
.content-part p {
  margin-bottom: 1rem;
}

.video-player {
  width: 100%;
  height: auto;
  border: 1px solid #c7c7c7;
}

iframe {
  width: 100%;
  height: 100%;
}

.question-stem {
  margin-bottom: 1rem;
}

.math-large {
  font-size: 2.25rem;
  line-height: 1rem;
}

.answer-options {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.answer-options li {
  margin: 0 !important;
  padding: 0 !important;
}
.answer-options li:marker {
  display: none;
}

.answer {
  margin-bottom: 1.5rem;
}
.answer .small-input {
  width: 3rem;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0.25rem;
}
.answer .inline-select {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.25rem;
}
.answer .small {
  font-size: 0.875rem;
}

.answer-status {
  background-color: #f5faf5;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
}
.answer-status .title {
  color: #038238;
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}

/* Player footer actions that switch to dropdown in mobile view */
.mobile-dd-trigger {
  display: none;
}
@media only screen and (max-width: 480px) {
  .mobile-dd-trigger {
    display: inline-flex;
    gap: 0.5rem;
  }
}

.item-actions {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  .item-actions {
    display: none;
    flex-direction: column;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 0.25rem 1rem;
  }
  .item-actions.open {
    display: flex;
    position: absolute;
    bottom: 3.25rem;
    left: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .item-actions .gr-btn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.Resizer {
  z-index: 1;
  box-sizing: border-box;
  position: relative;
}
.Resizer:before {
  content: "\e5d3";
  font-family: "Material Icons";
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Resizer:hover {
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 1px;
  background-color: #C2C7CC;
  cursor: row-resize;
  margin: 0.5rem 0;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.SplitPane.horizontal {
  position: static !important;
}

.Pane.horizontal {
  padding: 20px;
  overflow: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.Pane.horizontal::-webkit-scrollbar {
  width: 6px;
}
.Pane.horizontal::-webkit-scrollbar-track {
  background: transparent;
}
.Pane.horizontal::-webkit-scrollbar-thumb {
  background: #737373;
  border-radius: 4px;
}
.Pane.horizontal::-webkit-scrollbar-thumb:hover {
  background: #a3a5a7;
}
.Pane.Pane1 {
  flex: 1 1 60%;
}
.Pane.Pane2 {
  flex: 1 1 40%;
}
.Pane.Pane2 img {
  max-height: 400px;
}

/* Switch/Toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a9aca8 url(../images/icon_cross.png) no-repeat 28px center;
  transition: 0.4s;
  border-radius: 34px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  top: 3px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background: #5066c5 url(../images/icon_check.png) no-repeat 9px center;
}
.switch input:checked + .slider:before {
  transform: translateX(24px);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #047A9C;
}
.switch input:disabled + .slider, .switch input[disabled] + .slider {
  background-color: #C2C7CC;
}

.etext {
  display: block;
  width: 100vw;
  height: 100vh;
  background: #DDDEDF;
  position: relative;
}
.etext .etext__navbar {
  position: absolute;
  left: 0;
  width: 5.875rem;
  height: 100vh;
  background: #F7F9FD;
  box-shadow: 0px 1px 4px rgba(28, 34, 46, 0.05), 0px 2px 12px rgba(28, 34, 46, 0.06);
  border-radius: 0px 16px 16px 0px;
  padding: 2rem 1.5rem;
  z-index: 10;
}
.etext .etext__navbar > ul {
  margin: 2rem 0 0 0;
  list-style-type: none;
}
.etext .etext__navbar > ul li {
  margin-bottom: 1rem;
}
.etext .etext__navbar .btn-close {
  bottom: 2rem;
  left: 1.5rem;
  position: absolute;
}
.etext .etext__header {
  position: fixed;
  top: 0;
  height: 4rem;
  width: 100vw;
  z-index: 5;
  /*grid-column: 2;
  grid-row: 1; */
  background: linear-gradient(180deg, #F6F9FD 78.12%, rgba(247, 249, 253, 0) 100%);
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem;
}
.etext .etext__header h1 {
  font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.etext .etext__header .user-id {
  background: #F6F9FD;
  border: 1px solid #ECF1FB;
  border-radius: 20px;
  width: 2.5rem;
  height: 2.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.etext .etext__body {
  height: 100vh;
  padding-left: 5.875rem;
  overflow: auto;
}
.etext .etext__body .page {
  max-width: 64rem;
  background: #fff;
  margin: 0 auto;
  padding: 5rem 5rem 6rem;
}
.etext .etext__footer {
  /*grid-column: 2;
  grid-row: 3;*/
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 5.5rem;
  background: linear-gradient(180deg, #F6F9FD 38.64%, rgba(221, 222, 223, 0) 100%);
  transform: rotate(180deg);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0.5rem;
}
.etext .etext__footer img {
  transform: rotate(180deg);
}

.bronte-content {
  --color-primary: #232323;
  --color-secondary: rgba(26, 26, 26, 0.80); /*#1a1a1a;*/
  --color-tertiary: rgba(26, 26, 26, 0.65);
  --color-disabled: rgba(0, 0, 0, 0.50);
  --gradient-1-strong: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
  --gradient-1-light:linear-gradient(74deg, #C6CDFF 0.66%, #DEF1F1 99.66%);
  --gradient-2-strong: linear-gradient(74deg, #207C9E 0.66%, #262699 99.66%);
  --gradient-2-light:linear-gradient(74deg, #CDF3FF 0.66%, #E9EBFF 99.66%);
  --gradient-3-light:linear-gradient(51deg, #CDF3FF 23.98%, #7D8DE9 142.8%);
  --gradient-4-light:linear-gradient(0deg, #CDF3FF -91.25%, #E9EBFF 100%);
  --Neutral-Normal-Content-Primary: #232323;
  --Neutral-Normal-Content-Tertiary: #1A1A1AA6;
  --Neutral-Normal-Background-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
  --Neutral-Inverse-Background-Primary: #212121;
  --Theme-Accent-1-Lightest: #E9EBFF;
  --Theme-Accent-1-Lighter: #C6CDFF;
  --Theme-Accent-1-Light: #7D8DE9;
  --Theme-Accent-1-Base: #4858D4;
  --Theme-Accent-1-Dark: #3944BC;
  --Theme-Accent-1-Darker: #262699;
  --Theme-Accent-1-Darkest: #191966;
  --Theme-Accent-2-Lightest: #DEF1F1;
  --Theme-Accent-2-Lighter: #ACDEDA;
  --Theme-Accent-2-Light: #74C8C2;
  --Theme-Accent-2-Base: #009184;
  --Theme-Accent-2-Dark: #007567;
  --Theme-Accent-2-Darker: #00493B;
  --Theme-Accent-2-Darkest: #003329;
  --Theme-Accent-3-Lightest: #CDF3FF;
  --Theme-Accent-3-Lighter: #A8DCF3;
  --Theme-Accent-3-Light: #64A5C3;
  --Theme-Accent-3-Base: #207C9E;
  --Theme-Accent-3-Dark: #005A76;
  --Theme-Accent-3-Darker: #003248;
  --Theme-Accent-3-Darkest: #002333;
  --Neutral-Normal-Background-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
  --System-Informative-Lightest: #F1F7FE;
  --System-Informative-Lighter: #E3EEF7;
  --System-Informative-Light: #9ACDEF;
  --System-Informative-Base: #0075B1;
  --System-Informative-Dark: #00639B;
  --System-Informative-Darker: #003F65;
  --System-Informative-Darkest: #041E2D;
  --System-Positive-Lightest: #EEF6F6;
  --System-Positive-Lighter: #E0F0F0;
  --System-Positive-Light: #9AC1C1;
  --System-Positive-Base: #318181;
  --System-Positive-Dark: #006161;
  --System-Positive-Darker: #024040;
  --System-Positive-Darkest: #0B1E1E;
  --System-Attention-Lightest: #FDFBE7;
  --System-Attention-Lighter: #F5F2DB;
  --System-Attention-Light: #D2BA98;
  --System-Attention-Base: #8D7041;
  --System-Attention-Dark: #6A5329;
  --System-Attention-Darker: #47371A;
  --System-Attention-Darkest: #21190D;
  --System-Negative-Lightest: #FFF6F5;
  --System-Negative-Lighter: #FBEBE9;
  --System-Negative-Light: #FFA39E;
  --System-Negative-Base: #BF4044;
  --System-Negative-Dark: #9F2D34;
  --System-Negative-Darker: #691C20;
  --System-Negative-Darkest: #311211;
  font-family: "Poppins", sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

.global-footer {
  margin: 0;
  background: #F5F5F5;
}
.global-footer .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 2;
  gap: 1rem;
  padding: 0.5rem 0;
}
@media only screen and (max-width: 768px) {
  .global-footer .wrapper {
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
}
.global-footer p {
  margin: 0;
}
.global-footer a {
  color: #444;
}

.body-wrap {
  display: flex;
  min-height: 100vh;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(137.13deg, #EFEFEF 0%, #4CA8C3 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover, cover;
  background-attachment: fixed;
  overflow-x: hidden;
  font-family: Inter, Helvetica, sans-serif;
}
.body-wrap .main-nav {
  flex: 0 0 6.75rem;
}
.body-wrap main {
  flex: 1;
  mix-blend-mode: normal;
  min-width: calc(100vw - 6.75rem);
}
.body-wrap main .app-header {
  background: transparent;
}
.body-wrap main .wrapper {
  max-width: 64rem;
  margin: 2rem auto;
}
.body-wrap main .wrapper h2 {
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.5rem;
}
.body-wrap .section-header {
  background: linear-gradient(114.06deg, rgba(0, 0, 0, 0.3490196078) 0%, rgba(0, 0, 0, 0.1490196078) 100%), linear-gradient(159.55deg, #34b9dc 0%, #5c71cd 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.body-wrap .section-header .search-assignments {
  display: flex;
  align-items: center;
  color: white;
  gap: 0.5rem;
}
.body-wrap .section-header .search-assignments select {
  background-color: white;
  border-radius: 22px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  min-width: 15rem;
  margin-right: 1rem;
  color: #020917;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}
.body-wrap .section-header .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.body-wrap .section-header .actions .MuiButton-root {
  border: 2px solid white;
  color: white;
}
.body-wrap .section-header .actions .MuiToggleButton-root {
  border: 0;
  border-radius: 50% !important;
}
.body-wrap .section-header .actions .MuiToggleButton-root.Mui-selected {
  background-color: rgba(254, 254, 254, 0.56);
  color: #293e98;
}
.body-wrap .main-content {
  margin: 2rem 8rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}
.sidebar .header-logo {
  margin-bottom: 3rem;
}
.sidebar .main-menu {
  list-style: none;
  margin: 0px;
  padding: 8px 0px;
  position: relative;
}
.sidebar .main-menu .menu-item {
  padding: 0;
  border-radius: 50%;
  max-height: 3rem;
  margin: 1.5rem 0;
}
.sidebar .main-menu .menu-item a {
  display: block;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-indent: -9999px;
  background-color: rgba(2, 9, 23, 0.32);
}
.sidebar .main-menu .menu-item.selected a {
  background-color: #5bdda0;
}

.assignment-metadata {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.assignment-row {
  cursor: pointer;
}
.assignment-row:hover {
  background: rgba(254, 254, 254, 0.8);
}
.assignment-row .metadata {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
.assignment-row .assignment-title {
  margin: 0px 0px 8px;
  color: rgb(2, 9, 23);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.43;
  text-transform: none;
}
.assignment-row .assignment-title:hover {
  text-decoration: underline;
}

span.difficulty-label {
  color: #585858;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1; /* 100% */
  letter-spacing: 0.15px;
  display: block;
}

.table-wrapper {
  color: rgb(2, 9, 23);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(254, 254, 254, 0.9) 30%, rgba(254, 254, 254, 0.3) 90%);
}

.ov-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: Inter, Helvetica, sans-serif;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 2rem;
  border: 2px solid rgb(2, 9, 23);
  color: rgb(2, 9, 23);
  padding: 10px 24px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-transform: none;
  line-height: 1.42857;
}
.ov-button.outlined {
  border: 2px solid #fff;
  color: #fff;
}

.toggle-button-group .toggle-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-decoration: none;
  font-family: Inter, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 11px;
  border: 0;
  border-radius: 50% !important;
  color: rgba(254, 254, 254, 0.96);
}
.toggle-button-group .toggle-button.selected {
  background-color: rgba(254, 254, 254, 0.5607843137);
  color: #293e98;
}

.bronte-body {
  --font-family-primary: "Poppins", sans-serif;
  --horizontal-gap: 40px;
  --vertical-gap: 40px;
  --outside-padding: 80px;
  --text-color: rgba(0,0,0,0.8);
  --text-color-inverse: rgba(255,255,255,0.8);
}

.bronte-body * {
  font-family: var(--font-family-primary);
}

.bronte-body {
  position: relative;
  width: 100%;
  align-items: center;
  padding: initial;
  margin: initial;
  z-index: initial;
}
.bronte-body .stage-container {
  width: 100%;
}
.bronte-body .stage-box {
  width: 100%;
}
.bronte-body .spf-rce-stage {
  width: 100%;
  min-height: 50vh;
}

.spf-rce-section {
  width: 100%;
  padding: 0 var(--outside-padding);
}

.carousel-container .sc-carousel__content-container {
  position: relative;
  display: flex;
  width: 100%;
}
.carousel-container .sc-carousel__content-container .sc-carousel__pages-window {
  overflow: hidden;
  flex: 1;
  max-width: 100%;
}
.carousel-container .sc-carousel__content-container .sc-carousel__pages-window .sc-carousel__pages-container {
  display: flex;
  flex-direction: row;
  transition: transform 0.3s ease;
}
.carousel-container .sc-carousel__content-container .sc-carousel__pages-window .sc-carousel__pages-container .slide-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  padding: 20px;
  border-radius: 25px;
  border: 3px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
}
.carousel-container .sc-carousel__content-container .sc-carousel__pages-window .sc-carousel__pages-container .slide-container .slide-image {
  width: 70px;
  min-height: 70px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 50%;
  top: 0;
  left: 35px;
}
.carousel-container.image-carousel .sc-carousel__content-container .sc-carousel__pages-window .sc-carousel__pages-container .slide-container .slide-image {
  width: 100%;
  max-width: 400px;
  margin: auto;
  min-height: 270px;
  border: 3px solid rgb(255, 255, 255);
  border-radius: 0;
}
.carousel-container .custom-arrow-container {
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  background-color: transparent;
}
.carousel-container .custom-arrow-container .custom-arrow {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-style: solid;
  border-width: thin;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 100ms ease;
  cursor: pointer;
  border-color: #fff;
  fill: #5F6368;
  background-color: #fff;
  box-shadow: 0px 67px 19px 0px rgba(0, 0, 0, 0), 0px 43px 17px 0px rgba(0, 0, 0, 0.01), 0px 24px 14px 0px rgba(0, 0, 0, 0.05), 0px 11px 11px 0px rgba(0, 0, 0, 0.09), 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}
.carousel-container .custom-dots {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}
.carousel-container .custom-dots .sc-carousel-dot__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 2px;
}
.carousel-container .custom-dots .sc-carousel-dot__dot {
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
  transition: opacity 100ms ease;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.6980392157);
}
.carousel-container .custom-dots .sc-carousel-dot__dot.sc-carousel-dot__dot_active {
  opacity: 1;
  width: 8px;
  height: 8px;
}

.sticky-left .spf-rce-row {
  align-items: flex-start;
}
.sticky-left .spf-rce-row .spf-rce-column:first-of-type {
  position: sticky;
  top: 70px;
}

.spf-rce-section-background-wrapper {
  margin: 0px auto;
  width: 100%;
  max-width: 940px;
  padding: 60px;
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(224, 224, 224);
  border-radius: 0px;
  background-color: unset;
  background-position: 50% calc(50% - var(--scrollProgress));
  background-size: 110%;
}

.size-lg .spf-rce-section-background-wrapper {
  max-width: 1200px;
}

.size-sm .spf-rce-section-background-wrapper {
  max-width: 800px;
}

.size-xs .spf-rce-section-background-wrapper {
  max-width: 600px;
}

.spf-rce-section-content {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  background-color: unset;
  max-width: 1200px;
  margin: auto;
}

.spf-rce-row {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--horizontal-gap);
  width: 100%;
}
@media (min-width: 641px) {
  .spf-rce-row {
    flex-direction: row;
  }
}

.spf-rce-column {
  position: relative;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  min-width: 15px;
}

.tight-sections {
  padding-bottom: 0;
}
.tight-sections .spf-rce-section:not(.banner) .spf-rce-section-background-wrapper {
  padding-bottom: 0;
}
.tight-sections + .spf-rce-section {
  padding-top: 0;
}
.tight-sections + .spf-rce-section .spf-rce-section-background-wrapper {
  padding-top: 20px;
}
.tight-sections .spf-rce-section:last-of-type .spf-rce-section-background-wrapper {
  padding-bottom: 60px;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: var(--font-family-primary);
  font-size: 1rem;
  line-height: 1.3;
  font-weight: normal;
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
  white-space: pre-wrap;
  overflow-wrap: normal;
}

a, span {
  font-size: inherit;
}

a {
  text-decoration: underline;
}

a:hover, a:focus {
  text-decoration: none;
}

a.external {
  color: var(--text-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0.75em 0;
}
a.external:after {
  content: url("data:image/svg+xml,<svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.70117 21.9014C5.15117 21.9014 4.68034 21.7055 4.28867 21.3139C3.89701 20.9222 3.70117 20.4514 3.70117 19.9014V5.90137C3.70117 5.35137 3.89701 4.88053 4.28867 4.48887C4.68034 4.0972 5.15117 3.90137 5.70117 3.90137H12.7012V5.90137H5.70117V19.9014H19.7012V12.9014H21.7012V19.9014C21.7012 20.4514 21.5053 20.9222 21.1137 21.3139C20.722 21.7055 20.2512 21.9014 19.7012 21.9014H5.70117ZM10.4012 16.6014L9.00117 15.2014L18.3012 5.90137H14.7012V3.90137H21.7012V10.9014H19.7012V7.30137L10.4012 16.6014Z' fill='currentColor'/></svg>");
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: text-bottom;
  margin-left: 0.25em;
}

strong {
  font-weight: 700;
}

p + p, p + ul, ul + p, .spf-element-text + .spf-element-text {
  margin-top: 1rem;
}

.inline-icon {
  margin-right: 8px;
  vertical-align: text-bottom;
}

img.inline-icon {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
}

img.block-icon {
  width: 100px;
  height: 100px;
  display: block;
  margin: 0 auto 1rem auto;
}

ul, ol {
  margin-left: 2rem;
}

figure {
  margin: 0;
}
figure img {
  border-radius: 24px;
  overflow: hidden;
}
figure figcaption {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
figure.knowdl img {
  border-radius: 0;
}

.spf-element-text {
  color: var(--text-color);
}

.spf-element-text .with-icon > p:first-child {
  margin-left: 40px;
  text-indent: -40px;
}
.spf-element-text .with-icon ul {
  margin-left: 60px;
}

.spf-element-plugin iframe {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
}

.scroll-down {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28.504px; /* 118.765% */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.header-hero-xl h1,
.header-hero-xl h2,
.header-hero-xl h3,
.header-hero-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 5rem;
  line-height: 1;
  letter-spacing: -2%;
  font-weight: 400;
  margin: 0;
}
@media (max-width: 640px) {
  .header-hero-xl h1,
  .header-hero-xl h2,
  .header-hero-xl h3,
  .header-hero-xl h4 {
    font-size: 4rem;
  }
}

h1,
.header-h1-xl h1,
.header-h1-xl h2,
.header-h1-xl h3,
.header-h1-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 2.5rem;
  line-height: 130%;
  font-weight: 400;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  h1,
  .header-h1-xl h1,
  .header-h1-xl h2,
  .header-h1-xl h3,
  .header-h1-xl h4 {
    font-size: 2rem;
  }
}

.header-patient-encounter-xl h1,
.header-patient-encounter-xl h2,
.header-patient-encounter-xl h3,
.header-patient-encounter-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 2.5rem;
  line-height: 125%;
  font-weight: 500;
  margin: 0;
}
@media (max-width: 640px) {
  .header-patient-encounter-xl h1,
  .header-patient-encounter-xl h2,
  .header-patient-encounter-xl h3,
  .header-patient-encounter-xl h4 {
    font-size: 2rem;
  }
}

h2,
.header-h2-xl h1,
.header-h2-xl h2,
.header-h2-xl h3,
.header-h2-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  line-height: 122%;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  h2,
  .header-h2-xl h1,
  .header-h2-xl h2,
  .header-h2-xl h3,
  .header-h2-xl h4 {
    font-size: 1.75rem;
  }
}

h3,
.header-h3-xl h1,
.header-h3-xl h2,
.header-h3-xl h3,
.header-h3-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 1.875rem;
  line-height: 122%;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  h3,
  .header-h3-xl h1,
  .header-h3-xl h2,
  .header-h3-xl h3,
  .header-h3-xl h4 {
    font-size: 1.75rem;
  }
}

h4,
.header-h4-xl h1,
.header-h4-xl h2,
.header-h4-xl h3,
.header-h4-xl h4 {
  font-family: var(--font-family-primary);
  font-size: 1.75rem;
  line-height: 125%;
  font-weight: 600;
}
@media (max-width: 640px) {
  h4,
  .header-h4-xl h1,
  .header-h4-xl h2,
  .header-h4-xl h3,
  .header-h4-xl h4 {
    font-size: 1.5rem;
  }
}

.header-subhead-xl h1,
.header-subhead-xl h2,
.header-subhead-xl h3,
.header-subhead-xl h4,
.header-subhead-xl p {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  line-height: 160%;
  font-weight: 600;
}
@media (max-width: 640px) {
  .header-subhead-xl h1,
  .header-subhead-xl h2,
  .header-subhead-xl h3,
  .header-subhead-xl h4,
  .header-subhead-xl p {
    font-size: 1.5rem;
  }
}

.header-hero-lg h1,
.header-hero-lg h2,
.header-hero-lg h3,
.header-hero-lg h4 {
  font-family: var(--font-family-primary);
  font-size: 4.25rem;
  line-height: 54px;
  font-weight: 400;
  letter-spacing: -2%;
}
@media (max-width: 640px) {
  .header-hero-lg h1,
  .header-hero-lg h2,
  .header-hero-lg h3,
  .header-hero-lg h4 {
    font-size: 3rem;
  }
}

.header-h1-lg h1,
.header-h1-lg h2,
.header-h1-lg h3,
.header-h1-lg h4 {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  line-height: 125%;
  font-weight: 400;
}
@media (max-width: 640px) {
  .header-h1-lg h1,
  .header-h1-lg h2,
  .header-h1-lg h3,
  .header-h1-lg h4 {
    font-size: 2rem;
  }
}

.header-h2-lg h1,
.header-h2-lg h2,
.header-h2-lg h3,
.header-h2-lg h4 {
  font-family: var(--font-family-primary);
  font-size: 1.75rem;
  line-height: 122%;
  font-weight: 600;
}
@media (max-width: 640px) {
  .header-h2-lg h1,
  .header-h2-lg h2,
  .header-h2-lg h3,
  .header-h2-lg h4 {
    font-size: 1.75rem;
  }
}

.consider-your-patient-heading h3 {
  font-family: var(--font-family-primary);
  font-size: 1.75rem;
  line-height: 122%;
  font-weight: 400;
}
@media (max-width: 640px) {
  .consider-your-patient-heading h3 {
    font-size: 1.75rem;
  }
}

.header-patient-encounter-lg h1,
.header-patient-encounter-lg h2,
.header-patient-encounter-lg h3,
.header-patient-encounter-lg h4 {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  line-height: 125%;
  font-weight: 500;
  margin-bottom: 0;
}
@media (max-width: 640px) {
  .header-patient-encounter-lg h1,
  .header-patient-encounter-lg h2,
  .header-patient-encounter-lg h3,
  .header-patient-encounter-lg h4 {
    font-size: 1.875rem;
  }
}

h5 {
  font-family: var(--font-family-primary);
  font-size: 1.5rem;
  line-height: 160%;
  font-weight: 500;
}
@media (max-width: 640px) {
  h5 {
    font-size: 1.25rem;
  }
}

h6 {
  font-family: var(--font-family-primary);
  font-size: 1.125rem;
  line-height: 160%;
  font-weight: 500;
}
.body-xl p {
  font-family: var(--font-family-primary);
  font-size: 1.5rem;
  line-height: 160%;
  font-weight: 500;
}

p, .body-md p {
  font-family: var(--font-family-primary);
  font-size: 1.125rem;
  line-height: 160%;
  font-weight: 500;
}

.body-sm p, figcaption, figcaption p {
  font-family: var(--font-family-primary);
  font-size: 1rem;
  line-height: 160%;
  font-weight: 500;
}

.body-xl ol p, .body-xl ul p,
.body-md ol p, .body-md ul p,
.body-sm ol p, .body-sm ul p {
  font-weight: 600;
}

.label-xl-allcaps p {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  line-height: 160%;
  font-weight: 600;
  text-transform: uppercase;
}

.label-xl p {
  font-family: var(--font-family-primary);
  font-size: 1.375rem;
  line-height: 136%;
  font-weight: 400;
}

.label-overline p, .label-overline h3 {
  font-family: var(--font-family-primary);
  font-size: 1.5rem;
  line-height: 160%;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;
}

.label-lg p {
  font-family: var(--font-family-primary);
  font-size: 1.375rem;
  line-height: 136%;
  font-weight: 600;
}

.label-md p {
  font-family: var(--font-family-primary);
  font-size: 1rem;
  line-height: 150%;
  font-weight: 400;
}

.label-sm p {
  font-family: var(--font-family-primary);
  font-size: 0.875rem;
  line-height: 130%;
  font-weight: 400;
}

.label-xs p {
  font-family: var(--font-family-primary);
  font-size: 0.75rem;
  line-height: 133%;
  font-weight: 500;
}

.label-md p strong,
.label-sm p strong,
.label-xs p strong {
  font-weight: 700;
}

.overline-xl p,
.overline-xl h1,
.overline-xl h2 {
  font-family: var(--font-family-primary);
  font-size: 1.25rem;
  line-height: 120%;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
}

.overline-xl {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.overline-xl:before {
  content: "";
  width: 64px;
  height: 4px;
  margin-right: 32px;
  background-color: var(--text-color);
  display: inline-block;
  margin-top: 0.675em;
}

.overline-lg p {
  font-family: var(--font-family-primary);
  font-size: 1.125rem;
  line-height: 120%;
  font-weight: 700;
  text-transform: uppercase;
}

.overline-md p {
  font-family: var(--font-family-primary);
  font-size: 1rem;
  line-height: 120%;
  font-weight: 700;
  text-transform: uppercase;
}

.overline-sm p {
  font-family: var(--font-family-primary);
  font-size: 0.875rem;
  line-height: 120%;
  font-weight: 700;
  text-transform: uppercase;
}

.patient-encounter-chart-item p {
  font-family: var(--font-family-primary);
  font-size: 2rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.scroll-down p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.no-padding-section .spf-rce-section-background-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.no-padding-section + .spf-rce-section .spf-rce-section-background-wrapper {
  padding-top: 0;
}

.list-box ul, .list-box ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-box li {
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 0.75rem 1rem;
}
.list-box li + li {
  margin-top: 0.75rem;
}
.list-box.three-col-boxes ul li {
  margin: 0 !important;
}
@media (min-width: 641px) {
  .list-box.three-col-boxes ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
}
@media (min-width: 769px) {
  .list-box.three-col-boxes ul {
    grid-template-columns: repeat(3, 1fr);
  }
}

.list-box-rows .spf-rce-row {
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 0.75rem 1rem;
  align-items: center;
  margin: 4px 0;
}
.list-box-rows.slide-in-bottom .spf-rce-row {
  visibility: hidden;
  animation: slideInBottom 400ms forwards;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(1n) {
  animation-delay: 0.25s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(2n) {
  animation-delay: 0.5s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(3n) {
  animation-delay: 0.75s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(4n) {
  animation-delay: 1s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(5n) {
  animation-delay: 1.25s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(6n) {
  animation-delay: 1.5s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(7n) {
  animation-delay: 1.75s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(8n) {
  animation-delay: 2s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(9n) {
  animation-delay: 2.25s;
}
.list-box-rows.slide-in-bottom .spf-rce-row:nth-child(10n) {
  animation-delay: 2.5s;
}

img {
  display: block;
  width: 100%;
  max-width: 100%;
}

.white-box .spf-rce-section-content {
  border-radius: 30px;
  border: 1px solid #FFF;
  background: rgba(255, 255, 255, 0.52);
  -webkit-backdrop-filter: blur(40px);
          backdrop-filter: blur(40px);
  padding: 40px;
}
.white-box .spf-rce-section-content .aside {
  padding: 20px;
  border-radius: 20px;
  margin: 20px 0;
  border: 1px solid var(--Stroke-Container, rgba(255, 255, 255, 0.8));
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.17);
  box-shadow: -3px 7px 14px 0px rgba(0, 0, 0, 0.05);
}

.white-box.video-box .spf-rce-section-content {
  padding: 40px 0;
  overflow: hidden;
}

.two-col-boxes .spf-rce-row {
  align-items: flex-start;
}
.two-col-boxes .spf-rce-column:not(:has(.spf-rce-spacer)) {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 50px;
}
.two-col-boxes.consider-your-patient-on-right .spf-rce-column:last-of-type {
  padding: 20px;
}

.three-col-boxes .spf-rce-column:not(:has(.spf-rce-spacer)) {
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 1rem;
}
.three-col-boxes .spf-rce-row + .spf-rce-row {
  margin-top: var(--horizontal-gap);
}

.content-box-right .spf-rce-column:last-of-type {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 40px;
}

.content-box-left .spf-rce-column:first-of-type {
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 40px;
}

.layout-two-one .spf-rce-row .spf-rce-column {
  flex: 1;
}
.layout-two-one .spf-rce-row .spf-rce-column:first-of-type {
  flex: 2;
}
.layout-two-one .spf-rce-row + .spf-rce-row {
  margin-top: var(--horizontal-gap);
}

.layout-one-two .spf-rce-row .spf-rce-column {
  flex: 1;
}
.layout-one-two .spf-rce-row .spf-rce-column:last-of-type {
  flex: 2;
}
.layout-one-two .spf-rce-row + .spf-rce-row {
  margin-top: var(--horizontal-gap);
}

.centered {
  text-align: center;
}

.icon-80 {
  max-width: 80px;
  margin: auto;
}

@media (min-width: 769px) {
  .list-wrap ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
}

.caption-box, .white-box .white-box {
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  padding: 0.75rem 1rem;
}

.white-box .white-box {
  padding: 1.5rem;
}

.sidebar-emergency .spf-rce-section-background-wrapper .spf-rce-section-content {
  max-width: 75%;
  margin: 0;
}

.feature-centered {
  text-align: center;
}

.alert-box {
  padding-bottom: 0;
}
.alert-box .spf-rce-section-background-wrapper {
  padding-bottom: 0;
}
.alert-box + .spf-rce-section {
  padding-top: 0;
}
.alert-box + .spf-rce-section .spf-rce-section-background-wrapper {
  padding-top: 20px;
}

.alert-box .spf-rce-section-content {
  border-radius: 30px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: linear-gradient(0deg, var(--Container-Emergency, rgba(175, 46, 48, 0.5)) 0%, var(--Container-Emergency, rgba(175, 46, 48, 0.5)) 100%), rgba(60, 60, 60, 0.3);
  -webkit-backdrop-filter: blur(55px);
          backdrop-filter: blur(55px);
  --text-color: var(--text-color-inverse);
  padding: 40px;
  padding-left: 90px !important;
}
.alert-box .spf-rce-section-content h2, .alert-box .spf-rce-section-content h3, .alert-box .spf-rce-section-content h4, .alert-box .spf-rce-section-content p {
  color: var(--text-color);
}
.alert-box .spf-rce-section-content:before {
  content: url("data:image/svg+xml,%3Csvg width='62' height='60' viewBox='0 0 62 60' fill='none' xmlns='http://www.w3.org/2000/svg'><rect width='62' height='60' rx='30' fill='%23F3605D'/><rect x='1' y='1' width='60' height='58' rx='29' stroke='white' stroke-opacity='0.98' stroke-width='2'/><path d='M28.7295 35.2324L28.2578 13.5352H33.7158L33.2666 35.2324H28.7295ZM31.0205 46.4629C28.9316 46.4629 27.5391 45.1152 27.5391 43.1162C27.5391 41.0947 28.9316 39.7471 31.0205 39.7471C33.0869 39.7471 34.457 41.0947 34.457 43.1162C34.457 45.1152 33.0869 46.4629 31.0205 46.4629Z' fill='white'/></svg%3E ");
  display: block;
  width: 62px;
  height: 62px;
  position: absolute;
  left: 20px;
  top: 40px;
}

.spf-rce-section.banner {
  border-radius: 0;
  border-top: 3px solid #FFF;
  border-bottom: 3px solid #FFF;
  background: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(125px);
          backdrop-filter: blur(125px);
}
.spf-rce-section.banner .spf-rce-column {
  gap: var(--vertical-gap);
}

.screen.screen-padding .stage-container {
  padding: 80px 0px;
}

.screen.overview {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.screen.overview .spf-rce-section-background-wrapper {
  max-width: 1200px;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.screen.overview .spf-rce-row {
  align-items: center;
  flex-direction: column;
}
@media (min-width: 769px) {
  .screen.overview .spf-rce-row {
    flex-direction: row;
  }
  .screen.overview .spf-rce-row .spf-rce-column {
    max-width: 50%;
  }
  .screen.overview .spf-rce-row .spf-rce-column:last-of-type {
    position: absolute;
    height: 100%;
    max-width: 40%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .screen.overview .image-xl img {
    transform-origin: left center;
  }
}

.screen.full-height {
  min-height: 100vh;
}

.screen.lesson-opener .spf-rce-section-background-wrapper {
  padding: 120px 60px;
}
.screen.lesson-opener .white-box {
  padding: 0;
}
@media (min-width: 641px) {
  .screen.lesson-opener .white-box .spf-rce-row {
    position: unset;
  }
  .screen.lesson-opener .white-box .spf-rce-column {
    min-height: 100%;
  }
  .screen.lesson-opener .white-box .spf-rce-column:last-of-type {
    position: unset;
  }
  .screen.lesson-opener .white-box .spf-rce-column:last-of-type .spf-element-image img {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: bottom;
       object-position: bottom;
  }
}

.docked-image-left-right .spf-rce-column:first-of-type {
  display: none;
}
.docked-image-left-right .spf-rce-column:last-of-type {
  display: none;
}
@media (min-width: 769px) {
  .docked-image-left-right.white-box .spf-rce-section-content {
    padding: 80px 100px;
  }
  .docked-image-left-right .spf-rce-column:first-of-type {
    position: absolute;
    display: block;
    max-width: 200px;
    left: 0;
    top: 0;
    transform: translateX(-100%);
  }
  .docked-image-left-right .spf-rce-column:last-of-type {
    position: absolute;
    display: block;
    max-width: 200px;
    right: 0;
    bottom: 0;
    transform: translateX(100%);
  }
}

.docked-image-right .spf-rce-column:last-of-type {
  display: none;
}
@media (min-width: 769px) {
  .docked-image-right.white-box .spf-rce-section-content {
    padding: 80px 100px;
  }
  .docked-image-right .spf-rce-column:last-of-type {
    position: absolute;
    display: block;
    max-width: 200px;
    right: 0;
    top: 0;
    transform: translateX(100%);
  }
  .docked-image-right .spf-rce-column:last-of-type .docked-right-top img {
    transform: scale(1.3) translateY(-10%);
  }
}

.docked-image {
  padding: 0;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  max-height: 100%;
}
.docked-image .spf-rce-section-background-wrapper {
  padding: 0;
  max-width: 100%;
}
.docked-image .spf-rce-section-content {
  max-width: 100%;
}
.docked-image.image-right {
  right: 0;
}

.screen.patient-encounter {
  padding: 250px 0;
}
.screen.patient-encounter h1 {
  margin: 0;
}
.screen.patient-encounter .spf-rce-stage {
  position: relative;
  max-width: 1080px;
  margin: auto;
  padding: 0 40px;
  background-image: url("./bg_ipad_middle.png");
  background-repeat: repeat-y;
  background-size: 100%;
}
.screen.patient-encounter .spf-rce-stage:before {
  content: "";
  width: 100%;
  height: 250px;
  background-image: url("./bg_ipad_top.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -250px;
}
.screen.patient-encounter .spf-rce-stage:after {
  content: "";
  width: 100%;
  height: 250px;
  background-image: url("./bg_ipad_bottom.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -250px;
}
.screen.patient-encounter .spf-rce-section-background-wrapper {
  padding: 10px 0;
}
.screen.patient-encounter .spf-rce-section-content {
  background: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  padding: 20px;
}

.image-small {
  max-width: 25%;
  margin: 10px auto;
}

.video-placeholder {
  position: relative;
}
.video-placeholder:after {
  content: "";
  width: 70px;
  height: 70px;
  display: block;
  background-image: url("./icon_video.svg");
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.patient-encounter-chart-item {
  position: relative;
}
.patient-encounter-chart-item.item-temperature {
  padding-left: 90px;
}
.patient-encounter-chart-item.item-temperature:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  display: block;
  background-image: url(../images/influenza/icon_Temp.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.patient-encounter-chart-item.item-heartrate {
  padding-left: 90px;
}
.patient-encounter-chart-item.item-heartrate:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  display: block;
  background-image: url(../images/influenza/icon_Heart.png);
  background-size: contain;
  background-repeat: no-repeat;
}
.patient-encounter-chart-item h3 {
  font-family: var(--font-family-primary);
  font-size: 1.5rem;
  line-height: 160%;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0;
}

.patient-encounter-section:last-child {
  padding-bottom: 200px;
}
.patient-encounter-section .spf-rce-section-background-wrapper {
  max-width: 1200px;
}
.patient-encounter-section .spf-rce-section-content {
  position: relative;
  margin: auto;
  padding: 0 60px;
  background-image: url("./bg_ipad_middle.png");
  background-repeat: repeat-y;
  background-size: 100%;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row + .spf-rce-row {
  margin-top: 20px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .spf-element-text {
  padding: 20px 30px 0 30px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .spf-element-text:only-child {
  padding-bottom: 20px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .spf-element-text + .spf-element-text {
  padding-top: 0;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row figure img {
  border-radius: 0;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row figure.icon {
  padding: 20px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .label-overline.with-icon p:first-child, .patient-encounter-section .spf-rce-section-content .spf-rce-row .label-overline.with-icon h3 {
  margin-left: 60px;
  text-indent: -60px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .label-overline.with-icon p:first-child svg, .patient-encounter-section .spf-rce-section-content .spf-rce-row .label-overline.with-icon h3 svg {
  vertical-align: middle;
  margin-right: 10px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .patient-encounter-chart-item.with-icon {
  margin-top: -1rem;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .patient-encounter-chart-item.with-icon p:first-child {
  margin-left: 60px;
  text-indent: 0;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .patient-encounter-chart-item.with-icon p:first-child:has(svg) {
  text-indent: -60px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .patient-encounter-chart-item.with-icon p:first-child svg {
  vertical-align: middle;
  margin-right: 10px;
}
.patient-encounter-section .spf-rce-section-content .spf-rce-row .patient-encounter-prescription h4:has(svg) + p {
  margin-left: 48px;
}
.patient-encounter-section .spf-rce-section-content:before {
  content: "";
  width: 100%;
  height: 250px;
  background-image: url("./bg_ipad_top.png");
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: -250px;
}
.patient-encounter-section .spf-rce-section-content:after {
  content: "";
  width: 100%;
  height: 250px;
  background-image: url("./bg_ipad_bottom.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -250px;
}
.patient-encounter-section .patient-encounter-chart {
  padding-left: 50px !important;
  position: relative;
}
.patient-encounter-section .patient-encounter-chart:before {
  content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 60 60' fill='none'><path d='M25.6667 37.8L39.5333 37.8C41.4454 37.8 43 36.2454 43 34.3333L43 20.4667C43 18.5546 41.4454 17 39.5333 17L28.8354 17C27.9146 17 27.0317 17.3629 26.3817 18.0129L23.2129 21.1817C22.5629 21.8317 22.2 22.7146 22.2 23.6354L22.2 34.3333C22.2 36.2454 23.7546 37.8 25.6667 37.8ZM37.8 28.2667C37.8 28.7433 37.41 29.1333 36.9333 29.1333L34.3333 29.1333L34.3333 31.7333C34.3333 32.21 33.9433 32.6 33.4667 32.6L31.7333 32.6C31.2567 32.6 30.8667 32.21 30.8667 31.7333L30.8667 29.1333L28.2667 29.1333C27.79 29.1333 27.4 28.7433 27.4 28.2667L27.4 26.5333C27.4 26.0567 27.79 25.6667 28.2667 25.6667L30.8667 25.6667L30.8667 23.0667C30.8667 22.59 31.2567 22.2 31.7333 22.2L33.4667 22.2C33.9433 22.2 34.3333 22.59 34.3333 23.0667L34.3333 25.6667L36.9333 25.6667C37.41 25.6667 37.8 26.0567 37.8 26.5333L37.8 28.2667ZM19.6 26.9667C19.6 26.2462 19.0204 25.6667 18.3 25.6667C17.5796 25.6667 17 26.2462 17 26.9667L17 35.6333C17 39.7013 20.2988 43 24.3667 43L36.5 43C37.2204 43 37.8 42.4204 37.8 41.7C37.8 40.9796 37.2204 40.4 36.5 40.4L24.3667 40.4C21.7342 40.4 19.6 38.2658 19.6 35.6333L19.6 26.9667Z' fill='white'/></svg>");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #344259;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.patient-encounter-section .patient-encounter-intro {
  position: relative;
  padding-left: 50px !important;
}
.patient-encounter-section .patient-encounter-intro:before {
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 32 32' fill='none'><path d='M17.9993 29.3333C15.5993 29.3333 13.5549 28.4888 11.866 26.8C10.1771 25.1111 9.33268 23.0666 9.33268 20.6666V19.9C7.42157 19.5888 5.83268 18.6944 4.56602 17.2166C3.29935 15.7388 2.66602 14 2.66602 12V3.99996H6.66602V2.66663H9.33268V7.99996H6.66602V6.66663H5.33268V12C5.33268 13.4666 5.8549 14.7222 6.89935 15.7666C7.94379 16.8111 9.19935 17.3333 10.666 17.3333C12.1327 17.3333 13.3882 16.8111 14.4327 15.7666C15.4771 14.7222 15.9993 13.4666 15.9993 12V6.66663H14.666V7.99996H11.9993V2.66663H14.666V3.99996H18.666V12C18.666 14 18.0327 15.7388 16.766 17.2166C15.4993 18.6944 13.9105 19.5888 11.9993 19.9V20.6666C11.9993 22.3333 12.5827 23.75 13.7493 24.9166C14.916 26.0833 16.3327 26.6666 17.9993 26.6666C19.666 26.6666 21.0827 26.0833 22.2493 24.9166C23.416 23.75 23.9993 22.3333 23.9993 20.6666V18.4333C23.2216 18.1666 22.5827 17.6888 22.0827 17C21.5827 16.3111 21.3327 15.5333 21.3327 14.6666C21.3327 13.5555 21.7216 12.6111 22.4993 11.8333C23.2771 11.0555 24.2216 10.6666 25.3327 10.6666C26.4438 10.6666 27.3882 11.0555 28.166 11.8333C28.9438 12.6111 29.3327 13.5555 29.3327 14.6666C29.3327 15.5333 29.0827 16.3111 28.5827 17C28.0827 17.6888 27.4438 18.1666 26.666 18.4333V20.6666C26.666 23.0666 25.8216 25.1111 24.1327 26.8C22.4438 28.4888 20.3993 29.3333 17.9993 29.3333ZM25.3327 16C25.7105 16 26.0271 15.8722 26.2827 15.6166C26.5382 15.3611 26.666 15.0444 26.666 14.6666C26.666 14.2888 26.5382 13.9722 26.2827 13.7166C26.0271 13.4611 25.7105 13.3333 25.3327 13.3333C24.9549 13.3333 24.6382 13.4611 24.3827 13.7166C24.1271 13.9722 23.9993 14.2888 23.9993 14.6666C23.9993 15.0444 24.1271 15.3611 24.3827 15.6166C24.6382 15.8722 24.9549 16 25.3327 16Z' fill='white'/></svg>");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #6C5BD4;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.patient-encounter-section .spf-rce-showhide {
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: -30px;
  margin-right: -30px;
  background: transparent;
  border: none;
  width: calc(100% + 60px);
}

.aside.consider-your-patient .spf-element-image {
  margin-bottom: 10px;
}
.aside.consider-your-patient .spf-element-image + .spf-element-text {
  padding: 0 10px;
}
.aside.consider-your-patient.content-box-right .spf-rce-column:last-of-type {
  padding: 20px;
}

.aside.safety-alert {
  border-radius: 1rem;
  border: 4px solid rgba(219, 71, 70, 0.3019607843) !important;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(54.1601867676px);
          backdrop-filter: blur(54.1601867676px);
  padding: 60px 40px 40px 60px !important;
  text-align: start;
}
.aside.safety-alert h3 {
  color: #DB4746;
}
.aside.safety-alert:before {
  content: "";
  background: url("./safety-alert.png") no-repeat;
  background-size: contain;
  display: block;
  width: 100px;
  height: 100px;
  position: absolute;
  left: -30px;
  top: -30px;
  z-index: 1;
}

.patient-encounter-chart .spf-rce-section-content {
  padding-left: 80px !important;
}
.patient-encounter-chart .spf-rce-section-content:before {
  content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='60' height='60' viewBox='0 0 60 60' fill='none'><path d='M25.6667 37.8L39.5333 37.8C41.4454 37.8 43 36.2454 43 34.3333L43 20.4667C43 18.5546 41.4454 17 39.5333 17L28.8354 17C27.9146 17 27.0317 17.3629 26.3817 18.0129L23.2129 21.1817C22.5629 21.8317 22.2 22.7146 22.2 23.6354L22.2 34.3333C22.2 36.2454 23.7546 37.8 25.6667 37.8ZM37.8 28.2667C37.8 28.7433 37.41 29.1333 36.9333 29.1333L34.3333 29.1333L34.3333 31.7333C34.3333 32.21 33.9433 32.6 33.4667 32.6L31.7333 32.6C31.2567 32.6 30.8667 32.21 30.8667 31.7333L30.8667 29.1333L28.2667 29.1333C27.79 29.1333 27.4 28.7433 27.4 28.2667L27.4 26.5333C27.4 26.0567 27.79 25.6667 28.2667 25.6667L30.8667 25.6667L30.8667 23.0667C30.8667 22.59 31.2567 22.2 31.7333 22.2L33.4667 22.2C33.9433 22.2 34.3333 22.59 34.3333 23.0667L34.3333 25.6667L36.9333 25.6667C37.41 25.6667 37.8 26.0567 37.8 26.5333L37.8 28.2667ZM19.6 26.9667C19.6 26.2462 19.0204 25.6667 18.3 25.6667C17.5796 25.6667 17 26.2462 17 26.9667L17 35.6333C17 39.7013 20.2988 43 24.3667 43L36.5 43C37.2204 43 37.8 42.4204 37.8 41.7C37.8 40.9796 37.2204 40.4 36.5 40.4L24.3667 40.4C21.7342 40.4 19.6 38.2658 19.6 35.6333L19.6 26.9667Z' fill='white'/></svg>");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #344259;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
}

.patient-encounter-intro .spf-rce-section-content {
  padding-left: 80px !important;
}
.patient-encounter-intro .spf-rce-section-content:before {
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'><path d='M17.9993 29.3333C15.5993 29.3333 13.5549 28.4888 11.866 26.8C10.1771 25.1111 9.33268 23.0666 9.33268 20.6666V19.9C7.42157 19.5888 5.83268 18.6944 4.56602 17.2166C3.29935 15.7388 2.66602 14 2.66602 12V3.99996H6.66602V2.66663H9.33268V7.99996H6.66602V6.66663H5.33268V12C5.33268 13.4666 5.8549 14.7222 6.89935 15.7666C7.94379 16.8111 9.19935 17.3333 10.666 17.3333C12.1327 17.3333 13.3882 16.8111 14.4327 15.7666C15.4771 14.7222 15.9993 13.4666 15.9993 12V6.66663H14.666V7.99996H11.9993V2.66663H14.666V3.99996H18.666V12C18.666 14 18.0327 15.7388 16.766 17.2166C15.4993 18.6944 13.9105 19.5888 11.9993 19.9V20.6666C11.9993 22.3333 12.5827 23.75 13.7493 24.9166C14.916 26.0833 16.3327 26.6666 17.9993 26.6666C19.666 26.6666 21.0827 26.0833 22.2493 24.9166C23.416 23.75 23.9993 22.3333 23.9993 20.6666V18.4333C23.2216 18.1666 22.5827 17.6888 22.0827 17C21.5827 16.3111 21.3327 15.5333 21.3327 14.6666C21.3327 13.5555 21.7216 12.6111 22.4993 11.8333C23.2771 11.0555 24.2216 10.6666 25.3327 10.6666C26.4438 10.6666 27.3882 11.0555 28.166 11.8333C28.9438 12.6111 29.3327 13.5555 29.3327 14.6666C29.3327 15.5333 29.0827 16.3111 28.5827 17C28.0827 17.6888 27.4438 18.1666 26.666 18.4333V20.6666C26.666 23.0666 25.8216 25.1111 24.1327 26.8C22.4438 28.4888 20.3993 29.3333 17.9993 29.3333ZM25.3327 16C25.7105 16 26.0271 15.8722 26.2827 15.6166C26.5382 15.3611 26.666 15.0444 26.666 14.6666C26.666 14.2888 26.5382 13.9722 26.2827 13.7166C26.0271 13.4611 25.7105 13.3333 25.3327 13.3333C24.9549 13.3333 24.6382 13.4611 24.3827 13.7166C24.1271 13.9722 23.9993 14.2888 23.9993 14.6666C23.9993 15.0444 24.1271 15.3611 24.3827 15.6166C24.6382 15.8722 24.9549 16 25.3327 16Z' fill='white'/></svg>");
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background-color: #6C5BD4;
  border-radius: 50%;
  position: absolute;
  left: 10px;
  top: 10px;
}

.spf-rce-showhide {
  background: rgba(255, 255, 255, 0.7);
  border: 1px solid #fff;
  border-radius: 20px;
  margin: 10px 0;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 600;
}
.spf-rce-showhide .icon {
  background: rgba(255, 255, 255, 0.46);
  border: 1px solid var(--Stroke-Container, #FFF);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spf-rce-showhide .icon svg {
  width: 24px;
  height: 24px;
}
.spf-rce-showhide details {
  width: 100%;
}
.spf-rce-showhide details summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  padding: 20px;
  -webkit-appearance: none;
}
.spf-rce-showhide details summary .icon {
  display: flex;
}
.spf-rce-showhide details summary .hide-text {
  display: none;
}
.spf-rce-showhide details summary::-webkit-details-marker {
  display: none;
}
.spf-rce-showhide details[open] summary {
  border-bottom: 1px solid #fff;
}
.spf-rce-showhide details[open] summary .hide-text {
  display: block;
}
.spf-rce-showhide details[open] summary .show-text {
  display: none;
}
.spf-rce-showhide details[open] summary .icon svg {
  transform: rotate(180deg);
}
.spf-rce-showhide details[open] .show-hide-body {
  padding: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-20 {
  margin-top: 20px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-0 .spf-rce-section-background-wrapper {
  padding-top: 0 !important;
}

.check-list h3 {
  font-size: 1.25rem;
}
.check-list ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.check-list ul li {
  position: relative;
  padding-left: 48px;
  line-height: 24px;
  margin-bottom: 0.5rem;
}
.check-list ul li:before {
  display: inline-block;
  content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'><path d='M10 16.9L6 12.9L7.4 11.5L10 14.1L16.6 7.5L18 8.9L10 16.9Z' fill='%23FEF7FF'/></svg>");
  position: absolute;
  left: 0; /* place the SVG at the start of the padding */
  width: 24px;
  height: 24px;
  background-color: rgba(29, 27, 32, 0.4);
  border-radius: 2px;
}

/*wrapper*/
.spf-rce-flipcard {
  /* cards */
}
.spf-rce-flipcard .component-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.spf-rce-flipcard .component-wrapper .component-label, .spf-rce-flipcard .component-wrapper .component-credit, .spf-rce-flipcard .component-wrapper .component-caption, .spf-rce-flipcard .component-wrapper .component-instruction {
  margin: 0 5px;
  padding: 5px 0;
}
.spf-rce-flipcard .component-wrapper .component-label > p, .spf-rce-flipcard .component-wrapper .component-credit > p, .spf-rce-flipcard .component-wrapper .component-caption > p, .spf-rce-flipcard .component-wrapper .component-instruction > p {
  display: inline-block;
  margin: 0;
}
.spf-rce-flipcard .component-wrapper .component-title > p {
  display: inline-block;
  margin: 0;
}
.spf-rce-flipcard .wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
.spf-rce-flipcard .wrapper.stepper {
  display: block;
}
.spf-rce-flipcard .title-font {
  font-family: var(--title-font, "Open Sans", "sans-serif");
}
.spf-rce-flipcard .cards-wrapper {
  content-visibility: auto;
  justify-content: center;
  padding-bottom: 1.25em;
  height: 100%;
}
.spf-rce-flipcard .cards-wrapper.stepper {
  grid-auto-flow: column;
  height: calc(100% - 60px) !important;
  justify-content: unset;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding-top: 1.5vh;
  padding-bottom: 1.25em;
}
.spf-rce-flipcard .cards-wrapper:focus {
  outline: 0;
}
.spf-rce-flipcard .cards-container {
  align-content: center;
  display: grid;
  grid-gap: 0.8%;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  margin: 0;
  padding: 0;
  min-width: 100%;
  padding: 1% 2.2%;
  list-style: none;
  width: 100%;
}
.spf-rce-flipcard .cards-container:focus {
  outline: 0;
}
.spf-rce-flipcard .cards-container.isLoading {
  opacity: 0;
  transition: opacity 0.6s ease-in;
}
.spf-rce-flipcard .cards-container.stepper {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4%;
  grid-template-columns: repeat(var(--stepper-repeat), 96%);
  width: var(--stepper-slide-panel-width, 400%);
  transform: translate3d(0, 0, 0);
  transition: transform 0.6s ease-out;
  padding-left: 2%;
  padding-right: 2%;
  align-content: unset;
  justify-content: unset;
}
.spf-rce-flipcard .cards-container .card {
  border-radius: calc(var(--card-radius, 20px) * 1%);
  cursor: pointer;
  flex: auto;
  min-width: 100%;
  position: relative;
  min-height: 100px;
}
.spf-rce-flipcard .cards-container .card.hide {
  transition: opacity 1s ease-in-out;
  opacity: 0;
  filter: alpha(opacity=0);
  display: none;
}
.spf-rce-flipcard .cards-container .card.show {
  display: block;
  opacity: 1;
  filter: alpha(opacity=1);
  transition: opacity 1s ease-in-out;
}
.spf-rce-flipcard .cards-container .card:hover {
  content: "";
  animation: pulse 1.5s ease 0.2s infinite;
  border-radius: var(--card-radius, 20px);
}
.spf-rce-flipcard .cards-container .card:focus {
  content: "";
  animation: pulse 1.5s ease 0.2s infinite;
  outline: 0;
  box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
}
.spf-rce-flipcard .cards-container .card:focus-visible {
  /* Special note: If debugging tab order change outline:none|0 to a visible property */
  /* outline: 3px solid var(--primary-color-hover); */
  box-shadow: 0px 0px 0px 1px var(--primary-color-hover);
  outline: 2;
}
.spf-rce-flipcard .cards-container .card-container:focus,
.spf-rce-flipcard .cards-container .card:focus {
  box-shadow: 0px 0px 0px 2px var(--primary-color-hover);
  outline: 2;
}
.spf-rce-flipcard .cards-container .front,
.spf-rce-flipcard .cards-container .back {
  background-position: center;
  border-radius: var(--card-radius, 20px);
  box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.3);
  color: rgb(130, 130, 130);
  font-size: var(--fluid-scale-font-size);
  min-height: 100%;
  min-width: 100%;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  text-align: center;
  /* flipping & hide one side of the cards */
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
}
.spf-rce-flipcard .cards-container .front:focus,
.spf-rce-flipcard .cards-container .back:focus {
  box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
  outline: 0;
}
.spf-rce-flipcard .cards-container .front:focus-visible,
.spf-rce-flipcard .cards-container .back:focus-visible {
  outline: 0;
  box-shadow: 0px 0px 0px 1px rgb(14, 43, 232);
}
.spf-rce-flipcard .cards-container .front::after {
  border-radius: var(--card-radius, 20px);
  content: "";
  display: block;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.spf-rce-flipcard .cards-container .front::after:hover {
  opacity: 0.1;
}
.spf-rce-flipcard .cards-container .back {
  transform: rotateY(180deg);
  color: #000;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.spf-rce-flipcard .cards-container .back::after:hover {
  opacity: 0.3;
}
.spf-rce-flipcard .cards-container .card-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--card-radius, 20px);
  position: relative;
  transform-style: preserve-3d;
  padding: 0;
  list-style-type: none;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  perspective: 1000px;
  /* dynamically set sizes */
}
.spf-rce-flipcard .cards-container .card-container.active .front,
.spf-rce-flipcard .cards-container .card-container.active .back {
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}
.spf-rce-flipcard .cards-container .card-container.active .back {
  transform: rotateY(0deg);
}
.spf-rce-flipcard .cards-container .card-container.active .front {
  transform: rotateY(-180deg);
}
.spf-rce-flipcard .cards-container .card-container .fill {
  background-size: 100% auto !important;
  -o-object-fit: fill;
     object-fit: fill;
  background-position: center center !important;
}
.spf-rce-flipcard .cards-container .card-container .contain {
  background-size: contain !important;
  -o-object-fit: contain;
     object-fit: contain;
  background-position: center center !important;
}
.spf-rce-flipcard .cards-container .card-container .cover {
  background-size: cover !important;
  -o-object-fit: cover;
     object-fit: cover;
  background-position: top center !important;
}
.spf-rce-flipcard .cards-container .card-container:focus {
  content: "";
  animation: pulse 1.5s ease 0.2s infinite;
  box-shadow: 0 3px 8px -1px rgba(0, 0, 0, 0.5);
  outline: 0;
}
.spf-rce-flipcard .cards-container .card-container:focus-visible {
  /*  outline: 3px solid var(--primary-color-hover); */
  outline: 0;
  box-shadow: 0px 0px 0px 1px rgb(14, 43, 232);
}
.spf-rce-flipcard .cards-container .inner {
  box-sizing: border-box;
  perspective: inherit;
  transform: translateY(-50%) scale(0.94);
  left: 0;
  outline: 1px solid transparent;
  padding: 1rem;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 2;
}
.spf-rce-flipcard .cards-container .inner p h1,
.spf-rce-flipcard .cards-container .inner p h2,
.spf-rce-flipcard .cards-container .inner p h3,
.spf-rce-flipcard .cards-container .inner p h4,
.spf-rce-flipcard .cards-container .inner p h5 {
  font-family: var(--title-font);
  margin-block-start: unset !important;
  margin-block-end: unset !important;
  margin-bottom: 0.8em;
  margin-top: 0.8em;
}
.spf-rce-flipcard .cards-container .inner p p {
  margin-block-start: unset !important;
  margin-block-end: unset !important;
}
.spf-rce-flipcard .cards-container .front .inner p {
  font-size: var(--fluid-scale-font-size);
  margin-bottom: 1rem;
  position: relative;
}
.spf-rce-flipcard .cards-container .front .inner span {
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--body-font, "Open Sans", "sans-serif");
  font-weight: 300;
}

.spf-rce-flipcard .cards-container {
  gap: 20px;
}
.spf-rce-flipcard .cards-container .card {
  min-height: 250px;
}
.spf-rce-flipcard .cards-container .card .front .inner {
  top: unset;
  bottom: 0;
  background-color: #FFF;
  transform: none;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.spf-rce-flipcard .cards-container .card .front .inner:after {
  content: url("data:image/svg+xml, <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'> <path d='M7 20V11C7 10.45 7.19583 9.97917 7.5875 9.5875C7.97917 9.19583 8.45 9 9 9H17.2L15.6 7.4L17 6L21 10L17 14L15.6 12.6L17.2 11H9V20H7Z' fill='white'/></svg>");
  padding: 0px 8px;
  border-radius: 6px;
  background: #4E4E4E;
}

@keyframes slideInRight {
  0% {
    transform: translate3d(100%, 0px, 0px);
    visibility: visible;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translate3d(-100%, 0px, 0px);
    visibility: visible;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}
@keyframes slideInBottom {
  0% {
    transform: translate3d(0px, 100%, 0px);
    visibility: visible;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
  }
}
@keyframes rotateIn {
  0% {
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}
@keyframes updown {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-10%);
  }
}
.animation-bounce svg {
  animation: updown 2s ease 4;
}

.trigger-slide-in-right {
  visibility: hidden;
}

.slide-in-right {
  animation-name: slideInRight;
  animation-duration: 400ms;
  animation-delay: 0ms;
  visibility: visible;
}

.trigger-slide-in-left {
  visibility: hidden;
}

.slide-in-left {
  animation-name: slideInLeft;
  animation-duration: 400ms;
  animation-delay: 0ms;
  visibility: visible;
}

.trigger-slide-in-bottom {
  visibility: hidden;
}

.slide-in-bottom {
  animation-name: slideInBottom;
  animation-duration: 400ms;
  animation-delay: 0ms;
  visibility: visible;
}

.rotate-in {
  animation-name: rotateIn;
  animation-duration: 400ms;
  animation-delay: 0ms;
  visibility: visible;
}/*# sourceMappingURL=styles.css.map */