.tippy-box {    
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
    border-radius: 4px;
    background-color: #546E7A;
    color: #fff;
    padding: 4px 8px; 
    box-shadow: 0 0 25px rgba(0,0,0,.25);

    &[data-placement^=bottom] {
        >.tippy-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px;
            border-color: transparent transparent #546E7A transparent;
            top: -19px;
        }
    }
    &[data-placement^=top] {
        >.tippy-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px;
            border-color: #546E7A transparent transparent transparent;
            top: 100%;
        }
    }
    &[data-placement^=left] {
        >.tippy-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px;
            border-color: transparent transparent transparent #546E7A;
            top: 50%;
            left: 100%;
        }
    }
    &[data-placement^=right] {
        >.tippy-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 8px;
            border-color: transparent #546E7A transparent transparent;
            top: 50%;
            right: 100%;
            transform: translateY(50%);
        }
    }


    &.assignment-tip {
        background-color: #F7F8FC;
        margin-top: -3px;
        
        .tippy-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px;
            border-color: transparent transparent #f7f8fc transparent;
            top: -22px;
        }

        .assignment-tip__content {
            background-color: #F7F8FC;
            color: #1F2933;
            font-family: 'Inter', sans-serif;
            font-size: 0.875rem;
            padding: 1rem;

            .details-link {
                display: flex;
                justify-content: flex-end;
                font-size: 0.75rem;
            }

            > div {
                margin: 0 0 0.5rem 0;
                display: flex;
                align-items: center;
                gap: 0.5rem;            
            }
            
        }
    }
}